import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tab, useDisclosure } from "@nextui-org/react";
import { t, Trans } from "@lingui/macro";

import { required } from "@src/libs/types";
import { convertPrice } from "@src/libs/money";

import { useDispatch, useSelector } from "@src/model/store";
import * as orders from "@src/model/slices/orders";

import {
  Button,
  CardLoading,
  Flex,
  Head,
  Tabs,
  CardOrder,
  Image,
  Text,
  Card,
} from "@src/components";
import { PopupLayout } from "@src/layouts/PopupLayout";

import IconBack from "@src/assets/back.icon.svg";
import { debounce } from "@src/libs/utils";

export const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { orderId, tableId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [currentTab, setTab] = useState("draft");

  const posOrder = useSelector(orders.selectPosOrderByState("confirm"));

  const cartLines = useSelector(orders.selectPosOrderByState("draft")).lines;

  const currency = useSelector((state) => required(state.shops.currency));

  const posOrderPayment = useSelector((state) => state.orders.posOrderPayment);

  const [loading, setLoading] = useState(false);

  const isEmployee = useSelector((state) => state.user.isEmployee);

  const orderProductsQty =
    posOrder.lines.length > 0
      ? posOrder.lines.map((line) => line.qty).reduce((a, b) => a + b, 0)
      : 0;

  const cartProductsQty = cartLines
    .map((line) => line.qty)
    .reduce((a, b) => a + b, 0);

  const cartPriceTotal = useMemo(
    () =>
      cartLines
        .map((line) => {
          return line.price_unit * line.qty;
        })
        .reduce((a, b) => a + b, 0),
    [cartLines]
  );

  let cart = [
    {
      id: "draft",
      label: `${t`CURRENTLY_VIEWING_ITEM`} (${cartProductsQty})`,
    },
    {
      id: "confirm",
      label: `${t`ORDERED_ITEMS`} (${orderProductsQty})`,
    },
  ];

  useEffect(() => {
    if (orderId) {
      dispatch(
        orders.actions.doGetOrder({
          orderId: parseInt(orderId),
        })
      );
    }
  }, []);

  // useEffect(() => {
  //   if (tableId && orderId && posOrder) {
  //     dispatch(
  //       orders.actions.doPaymentOrderByTableId({
  //         order_id: parseInt(orderId),
  //         amount_paid: posOrder.amount_total | 0,
  //         payment_method_id: 2,
  //         payment_name: posOrder.name,
  //       })
  //     );
  //   }
  // }, []);

  const handleUpdateLineInCart = (qty: number, line: orders.OrderLine) => {
    let lineInCart = required(line);

    const optionIds: orders.ISubOptionPayload[] = lineInCart.option_ids.flatMap(
      (o) => {
        const option_product_ids = [o.option_product_id.id];
        const sub_option_product_ids = o.sub_option_product_ids.map(
          (sub) => sub.id
        );

        // Trả về một mảng mới chứa cả option_product_ids và sub_option_product_ids
        return [{ option_product_ids, sub_option_product_ids }];
      }
    );

    const productOrder: orders.IUpdateOrderLinePayload = {
      table_id: parseInt(tableId!),
      lines: [
        {
          product_id: lineInCart.product_id.id,
          qty: qty,
          state: "draft",
          line_id: lineInCart.id,
          option_ids: optionIds,
          sub_product_ids:
            lineInCart.sub_product_ids?.map((sub) => sub?.id) || [],
          note: lineInCart.notice,
        },
      ],
    };

    dispatch(orders.actions.doUpdateOrderLine(productOrder));
  };

  const debounceHandleUpdateLineInCart = debounce(handleUpdateLineInCart, 300);

  // confirm draft order
  const handleConfirmOrder = () => {
    const cartLinesPayload: orders.IUpdateOrderLinePayload = {
      table_id: parseInt(tableId!),
      lines: cartLines.map((orderLine) => {
        return {
          product_id: orderLine.product_id.id,
          qty: orderLine.qty,
          state: "confirm",
          sub_product_ids:
            orderLine.sub_product_ids?.map((sub) => sub?.id) || [],
          option_ids: orderLine.option_ids.flatMap((o) => {
            const option_product_ids = [o.option_product_id.id];
            const sub_option_product_ids = o.sub_option_product_ids.map(
              (sub) => sub.id
            );

            // Trả về một mảng mới chứa cả option_product_ids và sub_option_product_ids
            return [{ option_product_ids, sub_option_product_ids }];
          }),
          line_id: orderLine.id,
          note: orderLine.notice,
        };
      }),
    };

    setLoading(true);
    if (cartLinesPayload) {
      dispatch(orders.actions.doUpdateOrderLine(cartLinesPayload)).then(() => {
        navigate("/success");
        setLoading(false);
      });
    }
  };

  // Payment orders
  // C1:
  const handlePaymentOrder = (orderId: any, tableId: any) => {
    setLoading(true);
    if (setLoading && tableId && orderId && posOrder) {
      dispatch(
        orders.actions.doPaymentOrderByTableId({
          order_id: parseInt(orderId),
          amount_paid: posOrder.amount_total | 0,
          payment_method_id: 2,
          payment_name: posOrder.name,
        })
      )
        .unwrap()
        .then((res) => {
          res?.PosOrder.map((t) => {
            // Get string Base64
            const base64String = JSON.stringify(t.base64_pdf).replace(/"/g, "");
            const link = document.createElement("a");
            link.href = `data:application/pdf;base64,${base64String}`;
            link.download = `${t.name}` + "-payment.pdf";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setLoading(false);
            navigate("/employee");
          });
        });
    }
  };

  // C2:
  // const handlePaymentOrder = () => {
  //   setLoading(true);
  //   if (setLoading && posOrderPayment) {
  //     posOrderPayment.map((t) => {
  //       // Get string Base64
  //       const base64String = JSON.stringify(t.base64_pdf).replace(/"/g, "");
  //       const iframe = document.createElement("iframe");
  //       iframe.style.display = "none";
  //       iframe.src = `data:application/pdf;base64,${base64String}`;
  //       document.body.appendChild(iframe);

  //       setTimeout(() => {
  //         if (iframe.contentWindow) {
  //           iframe.contentWindow.focus();
  //           iframe.contentWindow.print();
  //           document.body.removeChild(iframe);
  //         }
  //       }, 1000);
  //       setLoading(false);
  //     });
  //   }
  // };

  return (
    <PopupLayout isOpen={isOpen} onClose={onClose}>
      {loading ? (
        <CardLoading></CardLoading>
      ) : (
        <div className="h-screen-mobile flex flex-col">
          {/* HEADER */}

          <Flex className="items-center justify-between p-4 pb-0">
            <IconBack
              className="text-lg3 cursor-pointer"
              onClick={() => navigate(-1)}
            ></IconBack>
            <Head sz="medium15" className="translate-x-1/2">
              <Trans>{`ORDER_SUMMARY`}</Trans>
            </Head>
            <Button
              color="primary"
              variant="light"
              size="md"
              className="px-0"
              onClick={onOpen}
            >
              <Trans>{`ORDER_CODE`}</Trans>
            </Button>
          </Flex>

          <div className="w-full p-4">
            <Tabs
              aria-label="category"
              items={cart}
              selectedKey={currentTab}
              onSelectionChange={(val) => {
                const keySelected = val.toString();
                setTab(keySelected);
              }}
            >
              {(item) => <Tab key={item.id} title={item.label}></Tab>}
            </Tabs>
          </div>
          {/* BODY */}

          <div className="overflow-y-scroll no-scrollbar flex-grow p-4">
            {currentTab === "draft" && (
              <div className="relative">
                {/* DRAFT PRODUCTS */}

                {cartProductsQty > 0 ? (
                  <div className="space-y-4">
                    {cartLines.length > 0 &&
                      cartLines.map((line, index) => {
                        const hasBorder = index !== cartLines.length - 1;

                        const product = required(line.product_id);

                        if (line.qty != 0) {
                          const subNames = line.sub_product_ids.map(
                            (sub) => sub.name
                          );
                          const optionNames = line.option_ids.map((sub) =>
                            sub.sub_option_product_ids.map((sub) => sub.name)
                          );

                          return (
                            <CardOrder
                              key={line.id}
                              title={product.name}
                              price={line.price_unit}
                              image={line.product_id.image_url}
                              description={
                                subNames.join(", ") +
                                ` ${optionNames.join(", ")}`
                              }
                              isViewOnly={false}
                              note={line.notice}
                              currency={currency.name || "VND"}
                              onAddToCart={(qty: number) => {
                                debounceHandleUpdateLineInCart(qty, line);
                              }}
                              hasBorder={hasBorder}
                              quantity={line.qty}
                              onChange={() =>
                                navigate(
                                  `/shops/5/tables/${tableId}/orders/${orderId}/detail/${product.id}/edit?line=${line.id}`
                                )
                              }
                            ></CardOrder>
                          );
                        }
                      })}
                  </div>
                ) : (
                  <CartEmpty />
                )}
              </div>
            )}

            {currentTab === "confirm" && (
              <div className="relative">
                {/*ORDERED PRODUCTS */}

                {posOrder.lines && posOrder.lines.length > 0 ? (
                  <div className="space-y-4">
                    {posOrder.lines.map((line, index) => {
                      const hasBorder =
                        index !== (posOrder.lines?.length || 0) - 1;

                      if (line && line.qty !== 0) {
                        const option_ids = line.option_ids.flatMap(
                          (option) => option.sub_option_product_ids
                        );
                        const optionNames = option_ids.map((o) => o.name);

                        const subNames = line.sub_product_ids.map(
                          (o) => o.name
                        );

                        return (
                          <div key={line.id}>
                            {isEmployee ? (
                              <CardOrder
                                key={line.id}
                                title={line.product_id.name}
                                price={line.price_unit}
                                image={line.product_id.image_url}
                                currency={currency.name || "VND"}
                                hasBorder={hasBorder}
                                isViewOnly={false}
                                onAddToCart={(qty: number) => {
                                  debounceHandleUpdateLineInCart(qty, line);
                                }}
                                quantity={line.qty}
                                note={line.notice}
                                description={
                                  optionNames.join(", ") +
                                  " " +
                                  subNames.join(", ")
                                }
                              ></CardOrder>
                            ) : (
                              <CardOrder
                                key={line.id}
                                title={line.product_id.name}
                                price={line.price_unit}
                                image={line.product_id.image_url}
                                currency={currency.name || "VND"}
                                hasBorder={hasBorder}
                                isViewOnly={true}
                                quantity={line.qty}
                                note={line.notice}
                                description={
                                  optionNames.join(", ") +
                                  " " +
                                  subNames.join(", ")
                                }
                              ></CardOrder>
                            )}
                          </div>
                        );
                      }
                    })}
                  </div>
                ) : (
                  <CartEmpty />
                )}
              </div>
            )}
          </div>

          {/* ACTION */}
          <div className="border-t border-neutral-800 p-4">
            {currentTab === "draft" && cartProductsQty > 0 && (
              <div className="border-neutral-800">
                <Button
                  className="w-full"
                  onClick={handleConfirmOrder}
                  size="lg"
                >
                  {t`CONFIRM_ORDER`}-{" "}
                  {convertPrice(cartPriceTotal, currency.name || "VND")}
                </Button>
              </div>
            )}
            {currentTab === "confirm" && posOrder.lines.length > 0 && (
              <Card radius="none" shadow="none">
                <Flex className="p-4 justify-between items-center h-11">
                  <Button
                    className="w-full"
                    onClick={() => {
                      handlePaymentOrder(orderId, tableId);
                    }}
                    size="lg"
                  >
                    {/* {t`PAYMENT_ORDER`}-{" "} */}
                    {t`Tạm Tính -`}{" "}
                    {convertPrice(
                      posOrder.amount_total,
                      currency.name || "VND"
                    )}
                  </Button>
                </Flex>
              </Card>
            )}
          </div>
        </div>
      )}
    </PopupLayout>
  );
};

const CartEmpty = () => {
  return (
    <div className="text-center px-10 absolute top-1/2 translate-y-1/2">
      <Image
        width={310}
        height={undefined as any}
        alt="Success"
        image="/success-image.png"
        defaultImage="/success-image.png"
      />
      <Head sz="bold18" className="pt-4 pb-1">
        <Trans>{`NO_ITEMS_IN_LIST`}</Trans>
      </Head>
      <Text sz="regular15">
        <Trans>{`ORDERED_ITEMS_DISPLAYED_IN_LIST`}</Trans>
      </Text>
    </div>
  );
};
