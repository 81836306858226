import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDisclosure } from "@nextui-org/react";
import { t, Trans } from "@lingui/macro";

import CardLang from "@src/components/blocks/CardLang";
import { Button, CardLoading, CardShop, QRPopup } from "@src/components";

import { useDispatch, useSelector } from "@src/model/store";
import * as orders from "@src/model/slices/orders";
import * as shops from "@src/model/slices/shops";

export const Shop = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { shopId, tableId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const shopInfo = useSelector((state) => state.shops.shopInfo);
  const tableInfo = useSelector((state) => state.shops.tableInfo);

  const [orderQrCode, setOrderQrCode] = useState("");
  const [orderId, setOrderId] = useState<number>();

  useEffect(() => {
    if (tableId) {
      dispatch(
        shops.actions.doGetTable({
          tableId: parseInt(tableId),
        })
      );
    }
  }, []);

  useEffect(() => {
    if (shopId) {
      dispatch(
        shops.actions.doGetPosConfig({
          shopId: parseInt(shopId),
        })
      );
    }
  }, []);

  const handleCreateOrder = () => {
    if (tableId && shopInfo.current_session_id) {
      dispatch(
        orders.actions.doGetOrderByTableId({
          session_id: shopInfo.current_session_id.id,
          table_id: parseInt(tableId),
        })
      )
        .unwrap()
        .then((res) => {
          if (!res) {
            dispatch(
              orders.actions.doCreateOrder({
                pos_session_id: shopInfo.current_session_id!.id,
                table_id: parseInt(tableId),
                list_product: [],
              })
            )
              .unwrap()
              .then((res) => {
                if (res) {
                  setOrderId(res.id);
                  setOrderQrCode(res.order_no.toString());
                  onOpen();
                }
              });
          } else {
            console.log(res);

            navigate(
              `/shops/${shopId}/tables/${tableId}/order/${res.id}/invitation`
            );
          }
        });
    }
  };

  const shareData = {
    title: "1Menu",
    text: "1Menu share to your friend",
    url: `https://1menu-web.hcm.init.vn/shops/${shopId}/tables/${tableId}/orders/${orderId}?passcode=${orderQrCode}`,
  };
  const handleShareLink = async () => {
    if (navigator.share && navigator.canShare(shareData)) {
      await navigator
        .share(shareData)
        .then(onClose)
        .catch((error) => console.log("Error sharing", error));
    } else {
      onClose;
    }
  };

  return (
    <>
      {shopInfo ? (
        <div className="h-screen-mobile px-10 relative flex flex-col items-center justify-center text-center">
          <CardShop
            address={shopInfo.address || ""}
            display_name={shopInfo.display_name || ""}
            image={shopInfo.image_url || ""}
            table={tableInfo.name || t`TABLE_NOT_FOUND`}
          ></CardShop>
          <div className="pt-6 space-y-4">
            <CardLang></CardLang>

            {/* ACTION */}
            <Button
              size="lg"
              isDisabled={!tableInfo}
              onPress={() => {
                handleCreateOrder();
              }}
              className="h-unit-13"
            >
              <Trans>{`start`}</Trans>
            </Button>
          </div>
          <QRPopup
            isOpen={isOpen}
            onClose={onClose}
            QR={`https://1menu-web.hcm.init.vn/shops/${shopId}/tables/${tableId}/orders/${orderId}?passcode=${orderQrCode}`}
            table={tableInfo.name || t`TABLE_NOT_FOUND`}
            onNavigate={() => {
              navigate(
                `/shops/${shopId}/tables/${tableId}/orders/${orderId}?passcode=${orderQrCode}`
              );
            }}
            description={`${t`FIRST_PERSON_VIEW_MENU_INSTRUCTIONS`}`}
            onShare={handleShareLink}
          ></QRPopup>
        </div>
      ) : (
        <CardLoading></CardLoading>
      )}
    </>
  );
};
