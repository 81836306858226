import { useDispatch, useSelector } from "@src/model/store";
import React, { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";

import { required } from "@src/libs/types";
import * as shops from "@src/model/slices/shops";
import * as orders from "@src/model/slices/orders";

import { QRPopup } from "@src/components";
import { t } from "@lingui/macro";
import { debounce } from "@src/libs/utils";

interface IPropsPopupLayout {
  children: React.ReactNode;
  className?: string;
  onClose: () => void;
  isOpen: boolean;
}

export const PopupLayout: React.FC<IPropsPopupLayout> = (props) => {
  const { tableId, shopId, orderId } = useParams();
  const dispatch = useDispatch();

  const tableInfo = useSelector((state) => required(state.shops.tableInfo));
  const posOrder = useSelector((state) => required(state.orders.posOrder));

  const { isOpen, onClose } = props;

  const shareData = {
    title: "1Menu",
    text: "1Menu share to your friend",
    url: `https://1menu-web.hcm.init.vn/shops/${shopId}/tables/${tableId}/orders/${posOrder.id}?passcode=${posOrder.order_no}`,
  };

  useEffect(() => {
    if (tableId) {
      dispatch(
        shops.actions.doGetTable({
          tableId: parseInt(tableId),
        })
      );
    }
  }, []);

  const fetchDataOrder = () => {
    if (document.visibilityState === "visible" && orderId) {
      dispatch(
        orders.actions.doGetOrder({
          orderId: parseInt(orderId),
        })
      );
    }
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", () => {});

    const fetchDataWithTimeout = setInterval(() => {
      if (document.visibilityState === "visible") {
        fetchDataOrder();
      }
    }, 5000);

    return () => {
      clearInterval(fetchDataWithTimeout);
      document.removeEventListener("visibilitychange", () => {});
    };
  }, []);

  const handleShareLink = async () => {
    if (navigator.share && navigator.canShare(shareData)) {
      await navigator
        .share(shareData)
        .then(onClose)
        .catch((error) => console.log("Error sharing", error));
    } else {
      onClose();
    }
  };

  return (
    <div className="w-full mx-auto">
      {props.children}

      {/* DIALOG */}
      {posOrder.order_no && (
        <QRPopup
          isOpen={isOpen}
          onClose={onClose}
          QR={`https://1menu-web.hcm.init.vn/shops/${shopId}/tables/${tableId}/orders/${posOrder.id}?passcode=${posOrder.order_no}`}
          table={tableInfo.name?.toString() || ""}
          onNavigate={onClose}
          onShare={handleShareLink}
          description={t`SHARE_ORDER_CODE`}
        ></QRPopup>
      )}
    </div>
  );
};
