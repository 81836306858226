import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
  shallowEqual,
} from "react-redux";
export { createSelector } from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";

import { slice as user } from "./slices/users";
import { slice as products, mutates } from "./slices/products";
import { slice as shops } from "./slices/shops";
import { slice as orders } from "./slices/orders";

const reducer = {
  [user.name]: user.reducer,
  [products.name]: products.reducer,
  [orders.name]: orders.reducer,
  [shops.name]: shops.reducer,
};

const cachedProducts = JSON.parse(
  localStorage.getItem("cachedProducts") || "[]"
);
const cachedCategories = JSON.parse(
  localStorage.getItem("cachedCategories") || "[]"
);

export let store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  preloadedState: {
    orders: {
      ...orders.getInitialState(),
      cartLines: [],
    },
    products: {
      categories: cachedCategories || [],
      products: cachedProducts || [],
      allProducts: [],
      searchTerm: "",
      productsRecommend: [],
    },
  },
});

export type Store = typeof store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const rootSelector = (state: RootState) => state;
export const useDispatch = () => useReduxDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = (
  selector,
  equalityFn = shallowEqual
) => useReduxSelector(selector, equalityFn as any);
