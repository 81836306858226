import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Trans, t } from "@lingui/macro";
import { useDisclosure } from "@nextui-org/react";

/**
 * Store
 */
import { useDispatch, useSelector } from "@src/model/store";
import * as products from "@src/model/slices/products";
import * as orders from "@src/model/slices/orders";

/**
 * Components
 */
import {
  Button,
  Card,
  Image,
  CardHeader,
  Divider,
  CardBody,
  Head,
  RadioGroup,
  Radio,
  CheckboxGroup,
  Checkbox,
  Flex,
  Text,
  Counter,
  CardLoading,
  Textarea,
} from "@src/components";
import { PopupLayout } from "@src/layouts/PopupLayout";

/**
 * Utils
 */
import { required } from "@src/libs/types";
import { convertPrice } from "@src/libs/money";
import { tw } from "@src/libs/tix";

// icons
import IconBack from "@src/assets/back.icon.svg";

export const EditPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { productId, orderId, tableId } = useParams();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const lineInCart = new URLSearchParams(location.search).get("line");

  const productDetail = useSelector((state) =>
    required(state.products.productDetail)
  );
  const currency = useSelector((state) => state.shops.currency);

  const detailProductInCart = useSelector((state) =>
    required(state.orders.posOrder).lines.find((l) => {
      const isMatchingLine = l.id.toString() === lineInCart;

      return isMatchingLine;
    })
  );

  const [qty, setQty] = useState(detailProductInCart?.qty || 0);
  const [note, setNote] = useState(detailProductInCart?.notice);
  const [checkboxSelected, setCheckboxSelected] = useState<number[]>(
    detailProductInCart?.sub_product_ids?.map((checkbox) => checkbox.id) || []
  );
  const [radioSelected, setRadioSelected] = useState<
    orders.ISubOptionPayload[]
  >(
    detailProductInCart?.option_ids?.map(
      (o) =>
        ({
          option_product_ids: o.option_product_id
            ? [o.option_product_id.id]
            : [],
          sub_option_product_ids: o.sub_option_product_ids
            ? o.sub_option_product_ids.map((subOption) => subOption.id)
            : [],
        } || [])
    ) || []
  );

  const handleGetOptions = (optionId: number, optionGroupId: number) => {
    const updatedOptions = [...radioSelected];

    const selectedOptionIndex = radioSelected.findIndex(
      (selectedOption) => selectedOption.option_product_ids[0] === optionGroupId
    );

    if (selectedOptionIndex !== -1) {
      // Option đã tồn tại trong selectedOptions, cập nhật lại giá trị
      updatedOptions[selectedOptionIndex] = {
        option_product_ids: [optionGroupId],
        sub_option_product_ids: [optionId],
      };
    } else {
      // Option chưa tồn tại trong selectedOptions, thêm nó vào mảng
      updatedOptions.push({
        option_product_ids: [optionGroupId],
        sub_option_product_ids: [optionId],
      });
    }

    setRadioSelected(updatedOptions);
  };

  //  update cart line
  const handleUpdateLineInCart = () => {
    const _productId = parseInt(productId!);

    const productOrder: orders.IUpdateOrderLinePayload = {
      table_id: parseInt(tableId!),
      lines: [
        {
          product_id: _productId,
          qty: qty,
          state: "draft",
          line_id: detailProductInCart?.id,
          option_ids: radioSelected,
          sub_product_ids: checkboxSelected,
          note: note,
        },
      ],
    };

    dispatch(orders.actions.doUpdateOrderLine(productOrder));

    navigate(-1);
  };

  // Back to page menu
  const handleClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (orderId) {
      dispatch(
        orders.actions.doGetOrder({
          orderId: parseInt(orderId),
        })
      );
    }
  }, [orderId]);

  useEffect(() => {
    if (productId) {
      dispatch(
        products.actions.doGetProductById({
          productId: parseInt(productId),
        })
      );
    }
  }, [productId]);

  useEffect(() => {
    if (detailProductInCart) {
      setQty(detailProductInCart.qty);
      setNote(detailProductInCart.notice);
    }
  }, [detailProductInCart]);

  if (!detailProductInCart) {
    return <></>;
  } else {
    return (
      <PopupLayout isOpen={isOpen} onClose={onClose}>
        {productDetail && productDetail.id === parseInt(productId!) ? (
          <div className="h-screen-mobile flex flex-col w-full relative ">
            <Flex className="items-center justify-between absolute top-4 left-0 right-0 w-full z-20 px-4">
              <IconBack
                className="text-xxl cursor-pointer"
                onClick={() => handleClick()}
              ></IconBack>

              <Button
                color="primary"
                variant="light"
                size="md"
                className="flex-shrink-0 px-0"
                onClick={onOpen}
              >
                <Trans>{`ORDER_CODE`}</Trans>
              </Button>
            </Flex>
            <div className="h-80">
              <Image
                width={448}
                alt="Product Image"
                height={undefined as any}
                defaultImage={
                  productDetail.image_url?.replace("image_1920", "image_128") ||
                  ""
                }
                image={productDetail.image_url || ""}
                className={tw`h-full w-full object-contain`}
              />
            </div>

            <Card
              className="flex-grow rounded-b-none z-20 border-b"
              shadow="none"
            >
              <CardHeader className="p-4 flex flex-col items-start gap-y-1.5">
                <Flex className="justify-between w-full">
                  <Head sz="bold18">{productDetail.name}</Head>
                  <Head sz="bold18">
                    {convertPrice(
                      productDetail.price_incl_tax ?? 0,
                      currency?.name || "VND"
                    )}
                  </Head>
                </Flex>
                <div>
                  <Text sz="regular13" className="text-neutral-300">
                    {productDetail.description_sale}
                  </Text>
                </div>

                <Textarea
                  minRows={1}
                  variant="faded"
                  placeholder={t`note`}
                  defaultValue={note !== "" ? note : undefined}
                  onValueChange={(val: string) => {
                    setNote(val);
                  }}
                />
              </CardHeader>
              {productDetail.option_product_ids &&
                productDetail.option_product_ids?.length > 0 && (
                  <Divider className="text-neutral-800" />
                )}

              <CardBody className="py-0 pt-4">
                <div className="flex-1 overflow-y-scroll no-scrollbar z-20">
                  {/* RADIO OPTION */}
                  {productDetail.option_product_ids &&
                    productDetail.option_product_ids.length > 0 &&
                    productDetail.option_product_ids.map((optionGroup) => {
                      if (optionGroup && detailProductInCart.option_ids) {
                        const optionSelectedInCart =
                          detailProductInCart.option_ids.find(
                            (o) => o.option_product_id.id === optionGroup.id
                          )?.sub_option_product_ids[0]?.id;

                        const radioKey = `${optionGroup.id}-${optionSelectedInCart}`;

                        return (
                          <RadioGroup
                            key={radioKey}
                            label={optionGroup.name}
                            onValueChange={(val) => {
                              handleGetOptions(parseInt(val), optionGroup.id);
                            }}
                            defaultValue={optionSelectedInCart?.toString()}
                          >
                            {optionGroup.sub_option_product_ids.map(
                              (option, index) => {
                                return (
                                  <Radio
                                    key={index}
                                    value={option.id.toString()}
                                    description={convertPrice(
                                      option.price_incl_tax || 0,
                                      currency?.name || "VND"
                                    )}
                                  >
                                    {option.name}
                                  </Radio>
                                );
                              }
                            )}
                          </RadioGroup>
                        );
                      }
                    })}
                  {/* CHECKBOX OPTION */}
                  {productDetail.sub_product_ids &&
                    productDetail.sub_product_ids.length > 0 &&
                    detailProductInCart.sub_product_ids && (
                      <CheckboxGroup
                        key={detailProductInCart.sub_product_ids[0]?.id.toString()}
                        label={`${t`ADD`} - topping`}
                        onValueChange={(val) => {
                          setCheckboxSelected(
                            val.map((item) => parseInt(item))
                          );
                        }}
                        defaultValue={detailProductInCart.sub_product_ids.map(
                          (e) => e.id.toString()
                        )}
                      >
                        {productDetail.sub_product_ids.map((option, index) => {
                          return (
                            <Checkbox
                              key={index}
                              value={option.id.toString()}
                              description={convertPrice(
                                option.price_incl_tax
                                  ? option.price_incl_tax
                                  : 0,
                                currency?.name || "VND"
                              )}
                            >
                              {option.name}
                            </Checkbox>
                          );
                        })}
                      </CheckboxGroup>
                    )}
                </div>
              </CardBody>
            </Card>
            <div className="flex p-4 gap-x-4 ">
              <Counter defaultValue={qty} onChange={setQty}></Counter>

              <Button
                className="w-full"
                size="lg"
                onClick={handleUpdateLineInCart}
              >
                {qty == 0 ? t`DELETE` : t`SAVE`}
              </Button>
            </div>
          </div>
        ) : (
          <CardLoading></CardLoading>
        )}
      </PopupLayout>
    );
  }
};
