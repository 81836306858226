import React from "react";

export const Home = () => {
  return (
    <div className="h-screen-mobile px-10 relative flex flex-col items-center justify-center text-center">
      <div className="pt-6 space-y-4">
        {`Vui lòng quét mã bàn
        hoặc liên hệ nhân viên để tiếp tục`}
      </div>
    </div>
  );
};
