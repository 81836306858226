import { i18n } from "@lingui/core";

export function swapCurrencyPosition(inputString: string) {
  if (inputString.includes("đ")) {
    const parts = inputString.split(" ");

    if (parts.length === 2) {
      return `${parts[1]} ${parts[0]}`;
    }
  }

  return inputString;
}

export const convertPrice = (price: number, currency: string) => {
  return swapCurrencyPosition(
    i18n
      .number(price, {
        currency: currency,
        style: "currency",
      })
      .replace("₫", "đ ")
  );
};
