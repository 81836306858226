import React from "react";
import { Outlet } from "react-router-dom";

const MainLayout = () => {
  return (
    <div
      id="mainLayout"
      className="max-w-md max-h-screen-mobile  w-full mx-auto font-inter"
    >
      <Outlet />
    </div>
  );
};

export default MainLayout;
