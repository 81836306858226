import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { t, Trans } from "@lingui/macro";

import { Button, Flex, Head, Input, Lozenge, Text } from "@src/components";
import { QRCodeScanner } from "@src/components/QRCodeScanner";

import { required } from "@src/libs/types";
import { debounce } from "@src/libs/utils";
import { tw } from "@src/libs/tix";

import { useDispatch, useSelector } from "@src/model/store";
import * as orders from "@src/model/slices/orders";
import * as shops from "@src/model/slices/shops";

import IconBack from "@src/assets/back.icon.svg";

export const Invitation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { shopId, tableId, orderId } = useParams();

  const posOrder = useSelector((state) => required(state.orders.posOrder));
  const tableInfo = useSelector((state) => required(state.shops.tableInfo));

  const [isCameraActive, setCameraActive] = useState(true);
  const [scannedText, setScannedText] = useState("");
  const [isInvalid, setInvalid] = useState(false);

  useEffect(() => {
    if (orderId) {
      dispatch(
        orders.actions.doGetOrder({
          orderId: parseInt(orderId),
        })
      );
    }
  }, []);

  useEffect(() => {
    if (tableId) {
      dispatch(
        shops.actions.doGetTable({
          tableId: parseInt(tableId),
        })
      );
    }
  }, []);

  const handleScanQRCode = useCallback(
    (decodedText: string) => {
      let QR;
      if (decodedText.includes("passcode=")) {
        QR = decodedText
          .toUpperCase()
          .substring(decodedText.indexOf("passcode=") + "passcode=".length);
      } else {
        QR = decodedText.toUpperCase();
      }

      if (posOrder.order_no === QR) {
        setInvalid(false);
        navigate(
          `/shops/${shopId}/tables/${tableId}/orders/${posOrder.id}?passcode=${QR}`
        );
      } else {
        setScannedText("");
        setInvalid(true);
      }
    },
    [posOrder, scannedText]
  );

  useEffect(() => {
    if (scannedText !== "") {
      setTimeout(() => {
        handleScanQRCode(scannedText);
      }, 1000);
    }
  }, [scannedText]);

  const handleInputQRCode = debounce(handleScanQRCode, 1000);

  const handleClick = () => {
    navigate(-1);
  };

  return (
    <div className="h-screen-mobile flex flex-col w-full relative text-center">
      {isCameraActive ? (
        <>
          <div className="absolute top-0 z-50">
            <Flex className="items-center p-4 relative">
              <IconBack
                className="text-xxl cursor-pointer text-neutral-900 absolute left-4"
                onClick={handleClick}
              ></IconBack>

              <Head
                sz="medium15"
                className="text-neutral-900 flex-shrink-0 cursor-pointer text-center items-center w-full"
              >
                <Trans>{`SCAN_ORDER_QR_CODE`}</Trans>
              </Head>
            </Flex>

            <div className="p-6">
              <Lozenge>{tableInfo.name}</Lozenge>

              <Text sz="regular15" className="pt-2 text-neutral-900">
                <Trans>{`START_VIEW_MENU_INSTRUCTIONS`}</Trans>
              </Text>
            </div>
            <div>
              <Text sz="regular15" className="text-neutral-900">
                <Trans>{`PLACE_QR_CODE_INSIDE_FRAME`}</Trans>
              </Text>
            </div>
          </div>
          <QRCodeScanner
            onResult={setScannedText}
            onError={(errorText: string) => {
              console.log(errorText);
            }}
          />

          {/* Input Order Code */}
          <div className="absolute bottom-20 w-full z-20">
            {isInvalid && (
              <Text sz="regular15" className="text-semantic-error-500">
                <Trans>{`UNABLE_TO_CONFIRM_CODE_TRY_AGAIN`}</Trans>
              </Text>
            )}

            <Button
              color="primary"
              variant="light"
              onClick={() => {
                setCameraActive(false), setInvalid(false);
              }}
            >
              <Trans>{`ENTER_ORDER_CODE`}</Trans>
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="bg-white px-4">
            <Flex className="items-center py-4 relative">
              <IconBack
                className="text-xxl cursor-pointer absolute"
                onClick={() => handleClick()}
              ></IconBack>

              <Head
                sz="medium15"
                className="text-neutral-100 flex-shrink-0 cursor-pointer text-center items-center w-full"
              >
                <Trans>{`ENTER_ORDER_CODE`}</Trans>
              </Head>
            </Flex>

            <div className="pt-6 text-center p-4">
              <Lozenge>{tableInfo.name}</Lozenge>
              <Text sz="regular15" className="pt-2 text-neutral-100">
                <Trans>{`JOIN_GROUP_ORDER_ENTER_CODE`}</Trans>
              </Text>
            </div>

            <div className="text-start">
              <Input
                type="text"
                variant={"bordered"}
                placeholder={t`ENTER_ORDER_CODE`}
                classNames={{
                  input: [
                    tw`text-neutral-100 text-sm2 uppercase`,
                    tw`placeholder:text-neutral-400 placeholder:text-sm2 placeholder:normal-case`,
                  ],
                  innerWrapper: tw`gap-2`,
                  inputWrapper: tw`border shadow-none`,
                }}
                errorMessage={
                  isInvalid ? t`UNABLE_TO_CONFIRM_CODE_TRY_AGAIN` : ""
                }
                autoCapitalize="characters"
                onValueChange={handleInputQRCode}
                isInvalid={isInvalid}
              ></Input>
            </div>
          </div>

          {/* QR Code */}
          <div className="w-full absolute bottom-4">
            <Button
              color="primary"
              variant="light"
              className="text-brand-ocean cursor-pointer text-center"
              onClick={() => setCameraActive(true)}
            >
              <Trans>{`SCAN_ORDER_QR_CODE`}</Trans>
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
