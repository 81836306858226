import { i18n } from "@lingui/core";
import { messages as en } from "@src/locales/en/messages";
import { messages as vi } from "@src/locales/vi/messages";

export const locales = {
  en: "English",
  vi: "Tiếng Việt",
};
export const defaultLocale = localStorage.getItem("lang") || "vi";

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */
export async function dynamicActivate(locale: string) {
  const { messages } = await import(`@src/locales/${locale}/messages`);
  i18n.load(locale, messages);
  i18n.activate(locale);
}
