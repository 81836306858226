import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "@src/model/store";
import * as shops from "@src/model/slices/shops";

import { Flex, Head } from "@src/components";
import { TableItem } from "@src/components/shared/Table";
import { useParams } from "react-router-dom";

export const Table = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { shopId } = useParams();

  let tables = useSelector((state) => state.shops.tables);
  let posOrders = useSelector((state) => state.shops.posOrderWithCode);

  useEffect(() => {
    if (shopId) {
      dispatch(
        shops.actions.doGetShopPosOrderWithCode({
          state: "draft",
          posId: parseInt(shopId),
        })
      );

      dispatch(
        shops.actions.doGetTables({
          posId: parseInt(shopId),
        })
      );
    }
  }, []);

  // Merge data and add isActive to check for State
  // Return parameter for navigate Table page
  const renderingTables = tables.map((t) => {
    const order = posOrders.find((order) => {
      return order.table_id?.id == t.id;
    });

    const isActive = !!order;

    return {
      ...t,
      isActive,
      orderId: order?.id,
      shopId: order?.config_id?.id,
      tableId: order?.table_id?.id,
      passCode: order?.order_no,
    };
  });

  return (
    <div className="h-screen-mobile flex flex-col bg-neutral-900 scroll-smooth">
      <div className="p-4">
        <Head sz="bold18" className="text-neutral-200">
          {`Danh sách các Bàn`}
        </Head>
        <Flex className="items-center gap-x-4 justify-between">
          <div className="table-grid">
            {renderingTables.map((t) => {
              return (
                <TableItem
                  key={t.id}
                  name={t.name}
                  isActive={t.isActive}
                  isDisabled={false}
                  onClick={() => {
                    navigate(
                      `/shops/${t.shopId}/tables/${t.tableId}/orders/${t.orderId}?passcode=${t.passCode}`
                    );
                  }}
                ></TableItem>
              );
            })}
          </div>
        </Flex>
      </div>
    </div>
  );
};
