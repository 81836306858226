import React, { useEffect, useState } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { NextUIProvider } from "@nextui-org/react";

import AppRoutes from "@src/routes";

import { defaultLocale } from "@src/libs/i18n";
import { messages as en } from "@src/locales/en/messages";
import { messages as vi } from "@src/locales/vi/messages";
import { store } from "@src/model/store";
import * as user from "@src/model/slices/users";

i18n.load({
  en: en,
  vi: vi,
});

i18n.activate(defaultLocale);

const App = () => {
  const [isLogged, setIsLogged] = useState(false);
  console.log("App is running!");

  useEffect(() => {
    store
      .dispatch(
        user.actions.doUserLogin({
          username: "admin",
          password: "init@123",
          isEmployee: false,
        })
      )
      .unwrap()
      .then((res) => {
        if (res) {
          setIsLogged(true);
        }
      });
  }, []);

  return (
    <NextUIProvider className="min-h-full h-full">
      <BrowserRouter>
        <ReduxProvider store={store}>
          <I18nProvider i18n={i18n}>{isLogged && <AppRoutes />}</I18nProvider>
        </ReduxProvider>
      </BrowserRouter>
    </NextUIProvider>
  );
};

export default App;
