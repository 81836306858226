import { PayloadAction, createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { typedName, dotPrefixer } from "@src/libs/types";
import { client } from "@src/model/api";

export const name = typedName("user");
export const thunkName = dotPrefixer(name);

const DO_USER_LOGIN = typedName("doUserLogin");

export const actions = {
  [DO_USER_LOGIN]: createAsyncThunk(
    thunkName(DO_USER_LOGIN),
    (
      // user must call action with the same payload schema
      payload: {
        username: string;
        password: string;
        isEmployee: boolean;
      },
      { dispatch, rejectWithValue }
    ) => {
      return client
        .post<{ jsonrpc: "2.0"; id: any; result: number }>(
          "/web/session/authenticate",
          {
            jsonrpc: "2.0",
            params: {
              // login: "admin",
              // password: "init@123",
              login: payload.username,
              password: payload.password,
              db: "init-odoo-menu-prod",
            },
          }
        )
        .then((res) => {
          if (res && !payload.isEmployee) {
            dispatch(mutates.setLogged(res.data.result));
            return res.data.result;
          } else {
            dispatch(
              mutates.setLogged(res.data.result) &&
                mutates.setLoginWithEmployee(res.data.result)
            );
            return res.data.result;
          }
        })
        .catch(rejectWithValue);
    }
  ),
};

export const slice = createSlice({
  name: name,
  initialState: {
    isLogged: false as boolean,
    isEmployee: false as boolean,
  },
  reducers: {
    setLogged: (state, { payload }: PayloadAction<number>) => {
      state.isLogged = payload != 0;
    },
    setLoginWithEmployee: (state, { payload }: PayloadAction<number>) => {
      state.isEmployee = payload != 0;
    },
  },
});

export const mutates = slice.actions;
