/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation CreateOrder($action: String!, $action_args: QueryInputDomainArg!) {\n    PosOrder(action: $action, action_args: $action_args) {\n      id\n      name\n      order_no\n      amount_total\n      lines {\n        id\n        name\n        state\n        qty\n        price_unit\n        product_id {\n          id\n          name\n          image_url\n        }\n        option_ids {\n          option_product_id {\n            id\n            name\n          }\n          sub_option_product_ids {\n            id\n            name\n          }\n        }\n        sub_product_ids {\n          id\n          name\n        }\n        notice\n      }\n    }\n  }\n": types.CreateOrderDocument,
    "\n  query PosOrder($domain: QueryInputDomainArg) {\n    PosOrder(domain: $domain) {\n      id\n      name\n      order_no\n      amount_total\n      lines {\n        id\n        name\n        price_unit\n        qty\n        state\n        product_id {\n          id\n          name\n          image_url\n        }\n        option_ids {\n          option_product_id {\n            id\n            name\n          }\n          sub_option_product_ids {\n            id\n            name\n          }\n        }\n        sub_product_ids {\n          id\n          name\n        }\n        notice\n      }\n    }\n  }\n": types.PosOrderDocument,
    "\n  query GetPosOrderIds($domain: QueryInputDomainArg) {\n    PosOrder(domain: $domain) {\n      id\n      name\n      order_no\n      config_id {\n        id\n        name\n      }\n    }\n  }\n": types.GetPosOrderIdsDocument,
    "\n  mutation GetPaymentOrderByTableId(\n    $action: String!\n    $action_args: QueryInputDomainArg!\n  ) {\n    PosOrder(action: $action, action_args: $action_args) {\n      id\n      name\n      table_id {\n        id\n        name\n      }\n      base64_pdf\n    }\n  }\n": types.GetPaymentOrderByTableIdDocument,
    "\n  query PosCategories($domain: QueryInputDomainArg) {\n    PosConfig(domain: $domain) {\n      iface_available_categ_ids {\n        id\n        name\n      }\n    }\n  }\n": types.PosCategoriesDocument,
    "\n  query PosCategoriesProducts($domain: QueryInputDomainArg) {\n    PosCategory(domain: $domain) {\n      id\n      name\n    }\n  }\n": types.PosCategoriesProductsDocument,
    "\n  query Products($domain: QueryInputDomainArg) {\n    ProductProduct(domain: $domain) {\n      id\n      name\n      price_incl_tax\n      image_url\n      description_sale\n      recommend\n      option_product_ids {\n        id\n        name\n        sub_option_product_ids {\n          id\n          name\n          price_incl_tax\n        }\n      }\n      sub_product_ids {\n        id\n        price_incl_tax\n        name\n      }\n      pos_categ_id {\n        id\n        name\n      }\n    }\n  }\n": types.ProductsDocument,
    "\n  query AllProducts(\n    $domain: QueryInputDomainArg\n    $limit: Int\n    $offset: Int\n    $order: String\n  ) {\n    ProductProduct(\n      domain: $domain\n      limit: $limit\n      offset: $offset\n      order: $order\n    ) {\n      id\n      name\n      price_incl_tax\n      image_url\n      description_sale\n      recommend\n      option_product_ids {\n        id\n        name\n        sub_option_product_ids {\n          id\n          name\n          price_incl_tax\n        }\n      }\n      sub_product_ids {\n        id\n        price_incl_tax\n        name\n      }\n      pos_categ_id {\n        id\n        name\n      }\n    }\n  }\n": types.AllProductsDocument,
    "\n  query PosConfig($domain: QueryInputDomainArg) {\n    PosConfig(domain: $domain) {\n      address\n      id\n      display_name\n      image_url\n      current_session_id {\n        id\n        name\n      }\n      floor_ids {\n        id\n        name\n        table_ids {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.PosConfigDocument,
    "\n  query CurrencyOfPosConfig($domain: QueryInputDomainArg) {\n    PosConfig(domain: $domain) {\n      id\n      name\n      currency_id {\n        id\n        name\n      }\n    }\n  }\n": types.CurrencyOfPosConfigDocument,
    "\n  query TableInfo($domain: QueryInputDomainArg) {\n    RestaurantTable(domain: $domain) {\n      id\n      name\n    }\n  }\n": types.TableInfoDocument,
    "\n  query Tables($domain: QueryInputDomainArg) {\n    PosConfig(domain: $domain) {\n      floor_ids {\n        table_ids {\n          id\n          name\n        }\n        id\n        name\n      }\n    }\n  }\n": types.TablesDocument,
    "\n  query PosOrderWithCode($domain: QueryInputDomainArg) {\n    PosOrder(domain: $domain) {\n      id\n      table_id {\n        id\n        name\n      }\n      order_no\n      state\n      config_id {\n        id\n        has_active_session\n      }\n    }\n  }\n": types.PosOrderWithCodeDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateOrder($action: String!, $action_args: QueryInputDomainArg!) {\n    PosOrder(action: $action, action_args: $action_args) {\n      id\n      name\n      order_no\n      amount_total\n      lines {\n        id\n        name\n        state\n        qty\n        price_unit\n        product_id {\n          id\n          name\n          image_url\n        }\n        option_ids {\n          option_product_id {\n            id\n            name\n          }\n          sub_option_product_ids {\n            id\n            name\n          }\n        }\n        sub_product_ids {\n          id\n          name\n        }\n        notice\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateOrder($action: String!, $action_args: QueryInputDomainArg!) {\n    PosOrder(action: $action, action_args: $action_args) {\n      id\n      name\n      order_no\n      amount_total\n      lines {\n        id\n        name\n        state\n        qty\n        price_unit\n        product_id {\n          id\n          name\n          image_url\n        }\n        option_ids {\n          option_product_id {\n            id\n            name\n          }\n          sub_option_product_ids {\n            id\n            name\n          }\n        }\n        sub_product_ids {\n          id\n          name\n        }\n        notice\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PosOrder($domain: QueryInputDomainArg) {\n    PosOrder(domain: $domain) {\n      id\n      name\n      order_no\n      amount_total\n      lines {\n        id\n        name\n        price_unit\n        qty\n        state\n        product_id {\n          id\n          name\n          image_url\n        }\n        option_ids {\n          option_product_id {\n            id\n            name\n          }\n          sub_option_product_ids {\n            id\n            name\n          }\n        }\n        sub_product_ids {\n          id\n          name\n        }\n        notice\n      }\n    }\n  }\n"): (typeof documents)["\n  query PosOrder($domain: QueryInputDomainArg) {\n    PosOrder(domain: $domain) {\n      id\n      name\n      order_no\n      amount_total\n      lines {\n        id\n        name\n        price_unit\n        qty\n        state\n        product_id {\n          id\n          name\n          image_url\n        }\n        option_ids {\n          option_product_id {\n            id\n            name\n          }\n          sub_option_product_ids {\n            id\n            name\n          }\n        }\n        sub_product_ids {\n          id\n          name\n        }\n        notice\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetPosOrderIds($domain: QueryInputDomainArg) {\n    PosOrder(domain: $domain) {\n      id\n      name\n      order_no\n      config_id {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetPosOrderIds($domain: QueryInputDomainArg) {\n    PosOrder(domain: $domain) {\n      id\n      name\n      order_no\n      config_id {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation GetPaymentOrderByTableId(\n    $action: String!\n    $action_args: QueryInputDomainArg!\n  ) {\n    PosOrder(action: $action, action_args: $action_args) {\n      id\n      name\n      table_id {\n        id\n        name\n      }\n      base64_pdf\n    }\n  }\n"): (typeof documents)["\n  mutation GetPaymentOrderByTableId(\n    $action: String!\n    $action_args: QueryInputDomainArg!\n  ) {\n    PosOrder(action: $action, action_args: $action_args) {\n      id\n      name\n      table_id {\n        id\n        name\n      }\n      base64_pdf\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PosCategories($domain: QueryInputDomainArg) {\n    PosConfig(domain: $domain) {\n      iface_available_categ_ids {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query PosCategories($domain: QueryInputDomainArg) {\n    PosConfig(domain: $domain) {\n      iface_available_categ_ids {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PosCategoriesProducts($domain: QueryInputDomainArg) {\n    PosCategory(domain: $domain) {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query PosCategoriesProducts($domain: QueryInputDomainArg) {\n    PosCategory(domain: $domain) {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Products($domain: QueryInputDomainArg) {\n    ProductProduct(domain: $domain) {\n      id\n      name\n      price_incl_tax\n      image_url\n      description_sale\n      recommend\n      option_product_ids {\n        id\n        name\n        sub_option_product_ids {\n          id\n          name\n          price_incl_tax\n        }\n      }\n      sub_product_ids {\n        id\n        price_incl_tax\n        name\n      }\n      pos_categ_id {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query Products($domain: QueryInputDomainArg) {\n    ProductProduct(domain: $domain) {\n      id\n      name\n      price_incl_tax\n      image_url\n      description_sale\n      recommend\n      option_product_ids {\n        id\n        name\n        sub_option_product_ids {\n          id\n          name\n          price_incl_tax\n        }\n      }\n      sub_product_ids {\n        id\n        price_incl_tax\n        name\n      }\n      pos_categ_id {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AllProducts(\n    $domain: QueryInputDomainArg\n    $limit: Int\n    $offset: Int\n    $order: String\n  ) {\n    ProductProduct(\n      domain: $domain\n      limit: $limit\n      offset: $offset\n      order: $order\n    ) {\n      id\n      name\n      price_incl_tax\n      image_url\n      description_sale\n      recommend\n      option_product_ids {\n        id\n        name\n        sub_option_product_ids {\n          id\n          name\n          price_incl_tax\n        }\n      }\n      sub_product_ids {\n        id\n        price_incl_tax\n        name\n      }\n      pos_categ_id {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query AllProducts(\n    $domain: QueryInputDomainArg\n    $limit: Int\n    $offset: Int\n    $order: String\n  ) {\n    ProductProduct(\n      domain: $domain\n      limit: $limit\n      offset: $offset\n      order: $order\n    ) {\n      id\n      name\n      price_incl_tax\n      image_url\n      description_sale\n      recommend\n      option_product_ids {\n        id\n        name\n        sub_option_product_ids {\n          id\n          name\n          price_incl_tax\n        }\n      }\n      sub_product_ids {\n        id\n        price_incl_tax\n        name\n      }\n      pos_categ_id {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PosConfig($domain: QueryInputDomainArg) {\n    PosConfig(domain: $domain) {\n      address\n      id\n      display_name\n      image_url\n      current_session_id {\n        id\n        name\n      }\n      floor_ids {\n        id\n        name\n        table_ids {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query PosConfig($domain: QueryInputDomainArg) {\n    PosConfig(domain: $domain) {\n      address\n      id\n      display_name\n      image_url\n      current_session_id {\n        id\n        name\n      }\n      floor_ids {\n        id\n        name\n        table_ids {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CurrencyOfPosConfig($domain: QueryInputDomainArg) {\n    PosConfig(domain: $domain) {\n      id\n      name\n      currency_id {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query CurrencyOfPosConfig($domain: QueryInputDomainArg) {\n    PosConfig(domain: $domain) {\n      id\n      name\n      currency_id {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query TableInfo($domain: QueryInputDomainArg) {\n    RestaurantTable(domain: $domain) {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query TableInfo($domain: QueryInputDomainArg) {\n    RestaurantTable(domain: $domain) {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Tables($domain: QueryInputDomainArg) {\n    PosConfig(domain: $domain) {\n      floor_ids {\n        table_ids {\n          id\n          name\n        }\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query Tables($domain: QueryInputDomainArg) {\n    PosConfig(domain: $domain) {\n      floor_ids {\n        table_ids {\n          id\n          name\n        }\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PosOrderWithCode($domain: QueryInputDomainArg) {\n    PosOrder(domain: $domain) {\n      id\n      table_id {\n        id\n        name\n      }\n      order_no\n      state\n      config_id {\n        id\n        has_active_session\n      }\n    }\n  }\n"): (typeof documents)["\n  query PosOrderWithCode($domain: QueryInputDomainArg) {\n    PosOrder(domain: $domain) {\n      id\n      table_id {\n        id\n        name\n      }\n      order_no\n      state\n      config_id {\n        id\n        has_active_session\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;