import React from "react";
import { Head, Lozenge, Image, Text } from "..";

interface ShopProps {
  address: string;
  display_name: string;
  table: string;
  image?: string;
}

export const CardShop: React.FC<ShopProps> = (props) => {
  return (
    <div className="space-y-1">
      <Head sz="medium26">{props.display_name}</Head>
      <Text>{props.address}</Text>
      <Lozenge>{props.table}</Lozenge>
      <div className="w-80 mx-auto pt-6">
        {props.image ? (
          <Image
            width={310}
            height={undefined as any}
            alt="food"
            image={props.image}
            defaultImage={props.image}
            className="h-60"
          />
        ) : (
          <Image
            width={310}
            height={undefined as any}
            alt="Shop Avatar"
            image="/shop-avatar.png"
            defaultImage="/shop-avatar.png"
          />
        )}
      </div>
    </div>
  );
};
