import React from "react";
import { tw } from "styled-tix";

import QRCode from "react-qr-code";
import { t, Trans } from "@lingui/macro";

import {
  Modal,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Lozenge,
  Text,
  Head,
  Divider,
} from "@src/components";

interface QRProps {
  isOpen: boolean;
  table: string;
  QR: string;
  description: string;
  onClose: () => void;
  onNavigate: () => void;
  onShare: () => void;
}

export const QRPopup: React.FC<QRProps> = (props) => {
  const _QRCode = props.QR.substring(
    props.QR.indexOf("passcode=") + "passcode=".length
  );

  return (
    <div>
      <Modal
        isOpen={props.isOpen}
        onClose={props.onClose}
        radius="lg"
        size="xs"
        placement="center"
        backdrop="blur"
        classNames={{
          footer: tw`flex flex-col text-center gap-3`,
          backdrop: tw`bg-neutral-500 backdrop-opacity-70 bg-opacity-70`,
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-2 items-center text-center">
                <Lozenge>{props.table}</Lozenge>
                <Text sz="regular13" className="text-neutral-100">
                  {props.description}
                </Text>
                <Divider className="text-neutral-800 my-1"></Divider>
                <Head sz="bold18" className="text-neutral-200">
                  {t`ORDER_CODE`}: {_QRCode}
                </Head>
                <Head sz="bold18" className="text-neutral-200">
                  <Trans>{`QR`}</Trans>
                </Head>
                <div className="h-36 w-36 border-2 p-3 rounded-xl border-brand-ocean">
                  <QRCode
                    size={145}
                    style={{
                      height: "auto",
                      maxWidth: "100%",
                      width: "100%",
                    }}
                    value={props.QR}
                    viewBox={`0 0 145 145`}
                  />
                </div>
              </ModalHeader>

              <ModalFooter>
                <Button
                  size="lg"
                  color="secondary"
                  variant="light"
                  onPress={props.onNavigate}
                >
                  <Trans>{`VIEW_MENU`}</Trans>
                </Button>

                <Button
                  color="primary"
                  variant="light"
                  size="lg"
                  className="text-brand-ocean"
                  // isDisabled
                  onPress={props.onShare}
                >
                  <Trans>{`SHARE_CODE`}</Trans>
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};
