import React from "react";
import { tw } from "styled-tix";
import { i18n } from "@lingui/core";

import { swapCurrencyPosition } from "@src/libs/money";

import {
  Text,
  Image,
  Head,
  Flex,
  Divider,
  TextFoot,
  Counter,
} from "@src/components";

import IconNote from "@src/assets/note.icon.svg";
import { Trans } from "@lingui/macro";

interface CommonCardProps {
  title: string;
  price: number;
  description?: string;
  image: string;
  currency?: string;
  hasBorder?: boolean;
  note?: string;
  quantity: number;
  onChange?: () => void;
}

interface ViewOnlyCardProps extends CommonCardProps {
  isViewOnly: true;
}

interface EditableCardProps extends CommonCardProps {
  isViewOnly: false;
  onAddToCart: (v: number) => any;
}

type CardProps = ViewOnlyCardProps | EditableCardProps;

export const CardOrder: React.FC<CardProps> = (props) => {
  const price = i18n
    .number(props.price, {
      currency: props.currency,
      style: "currency",
    })
    .replace("₫", "đ ");

  return (
    <Flex className="space-x-3 bg-white">
      {/* LEFT SIDE IMAGE */}
      <Image
        height={80}
        width={80}
        alt="Order"
        defaultImage={props.image.replaceAll("image_1920", "image_128")}
        image={props.image}
        className={tw`overflow-hidden max-h-20 w-20 object-cover aspect-w-1 rounded-md`}
      />

      {/* CONTENT */}
      <div className="flex-1 overflow-hidden">
        <Head
          sz="medium15"
          className="text-ellipsis line-clamp-1 overflow-clip"
        >
          {props.title}
        </Head>
        <Text
          sz="regular13"
          className="text-neutral-300 line-clamp-3 text-ellipsis capitalize"
        >
          {props.description}
        </Text>
        {props.note && (
          <Flex className="pt-1.5 capitalize">
            <div className="w-5 h-5 text-lg2 text-neutral-300">
              <IconNote />
            </div>
            <Text sz="regular13" className="text-neutral-300 pl-1">
              {props.note}
            </Text>
          </Flex>
        )}
        <Flex className="py-3 justify-between">
          <Text sz="regular13" className="text-neutral-300 line-clamp-1">
            {swapCurrencyPosition(price)}
          </Text>

          {/* BTN ACTION */}

          {props.isViewOnly ? (
            <div className="w-6 text-neutral-100">
              <TextFoot sz="medium13">x{props.quantity}</TextFoot>
            </div>
          ) : (
            <Counter
              defaultValue={props.quantity}
              onChange={(selected) => {
                props.onAddToCart(selected);
              }}
            ></Counter>
          )}
        </Flex>
        {props.isViewOnly ? (
          <div></div>
        ) : (
          <Head
            sz="medium13"
            className="text-brand-ocean cursor-pointer "
            onClick={props.onChange}
          >
            <Trans>{`CHANGE`}</Trans>
          </Head>
        )}

        {props.hasBorder && (
          <div className="pt-4">
            <Divider orientation="horizontal" />
          </div>
        )}
      </div>
    </Flex>
  );
};
