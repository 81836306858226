import { PayloadAction, createSlice, createSelector } from "@reduxjs/toolkit";

import {
  dotPrefixer,
  required,
  typedName,
  makePayload,
  NullableNestedOriginal,
  NonNullableNested,
} from "@src/libs/types";
import { graphql } from "@src/gql";
import { gqlClient } from "@src/model/api";
import { createAsyncThunk } from "@src/model/thunk";
import {
  PosOrderQuery,
  GetPosOrderIdsQuery,
  GetPaymentOrderByTableIdMutation,
} from "@src/gql/graphql";
import { rootSelector } from "../store";
import { equals } from "remeda";

export const name = typedName("orders");
export const thunkName = dotPrefixer(name);

const DO_GET_POS_ORDER_IDS = typedName("doGetPosOrderIds");
const DO_CREATE_ORDER = typedName("doCreateOrder");
const DO_GET_ORDER = typedName("doGetOrder");
const DO_UPDATE_ORDER = typedName("doUpdateOrder");
const DO_UPDATE_ORDER_LINE = typedName("doUpdateOrderLine");
const DO_GET_ORDER_BY_TABLEID = typedName("doGetOrderByTableId");
const DO_PAYMENT_ORDER_BY_TABLE_ID = typedName("doPaymentOrderByTableId");

export type GetPosOrderIds = NullableNestedOriginal<
  NonNullableNested<GetPosOrderIdsQuery>["PosOrder"][number]
>;

export type GetPaymentOrderByTableId = NullableNestedOriginal<
  NonNullableNested<GetPaymentOrderByTableIdMutation>["PosOrder"][number]["base64_pdf"]
>;

export type PosOrder = NullableNestedOriginal<
  NonNullableNested<PosOrderQuery>["PosOrder"][number]
>;
export type OrderLine = NullableNestedOriginal<
  NonNullableNested<PosOrderQuery>["PosOrder"][number]["lines"][number]
>;

const mutationCreateOrder = graphql(`
  mutation CreateOrder($action: String!, $action_args: QueryInputDomainArg!) {
    PosOrder(action: $action, action_args: $action_args) {
      id
      name
      order_no
      amount_total
      lines {
        id
        name
        state
        qty
        price_unit
        product_id {
          id
          name
          image_url
        }
        option_ids {
          option_product_id {
            id
            name
          }
          sub_option_product_ids {
            id
            name
          }
        }
        sub_product_ids {
          id
          name
        }
        notice
      }
    }
  }
`);

const queryPosOrderById = graphql(`
  query PosOrder($domain: QueryInputDomainArg) {
    PosOrder(domain: $domain) {
      id
      name
      order_no
      amount_total
      lines {
        id
        name
        price_unit
        qty
        state
        product_id {
          id
          name
          image_url
        }
        option_ids {
          option_product_id {
            id
            name
          }
          sub_option_product_ids {
            id
            name
          }
        }
        sub_product_ids {
          id
          name
        }
        notice
      }
    }
  }
`);

const queryGetPosOrderIds = graphql(`
  query GetPosOrderIds($domain: QueryInputDomainArg) {
    PosOrder(domain: $domain) {
      id
      name
      order_no
      config_id {
        id
        name
      }
    }
  }
`);

const mutationGetPaymentOrderByTableId = graphql(`
  mutation GetPaymentOrderByTableId(
    $action: String!
    $action_args: QueryInputDomainArg!
  ) {
    PosOrder(action: $action, action_args: $action_args) {
      id
      name
      table_id {
        id
        name
      }
      base64_pdf
    }
  }
`);

export const selectPosOrderByState = (orderState: "draft" | "confirm") =>
  createSelector(rootSelector, (state) => {
    const posOrder = required(state.orders.posOrder);

    // Lọc lines theo trạng thái
    const filteredLines = posOrder.lines?.filter(
      (l) => l.state && l.state.includes(orderState)
    );

    const orderWithFilteredLines = { ...posOrder, lines: filteredLines };

    return orderWithFilteredLines;
  });

export interface ICreateOrderPayload {
  pos_session_id: number;
  table_id: number;
  list_product: IListProductPayload[];
  other?: unknown;
}
export interface IListProductPayload {
  product_id: number;
  qty: number;
  sub_product_ids: number[];
  note?: string;
  options_ids?: ISubOptionPayload[];
}

export interface IUpdateOrderLinePayload {
  table_id: number;
  lines: IOrderLinePayload[];
}

export interface IOrderLinePayload {
  product_id: number;
  state: "draft" | "confirm";
  line_id?: number;
  order_id?: number;
  qty: number;
  sub_product_ids?: number[];
  note?: string;
  option_ids?: ISubOptionPayload[];
}

export interface ISubOptionPayload {
  option_product_ids: number[];
  sub_option_product_ids: number[];
}

export const actions = {
  [DO_GET_POS_ORDER_IDS]: createAsyncThunk(
    thunkName(DO_GET_POS_ORDER_IDS),
    (
      payload: {
        isPosOrders: boolean;
      },
      { dispatch, rejectWithValue }
    ) => {
      return gqlClient
        .request(queryGetPosOrderIds, {
          domain: [["config_id.id", "!=", payload.isPosOrders]],
        })
        .then((data) => {
          if (data.PosOrder && data.PosOrder.length > 0) {
            // Get posConfig(id, name) for Employee page
            const posOrders = required(data).PosOrder.map((t) => t.config_id);
            if (posOrders && posOrders.length > 1) {
              dispatch(setPosOrderIds(posOrders));
              return posOrders;
            }
          }
        })
        .catch(rejectWithValue);
    }
  ),

  [DO_CREATE_ORDER]: createAsyncThunk(
    thunkName(DO_CREATE_ORDER),
    (payload: ICreateOrderPayload, { dispatch, rejectWithValue }) => {
      return gqlClient
        .request(mutationCreateOrder, {
          action: "create_from_e_menu",
          action_args: makePayload<typeof payload>({
            pos_session_id: payload.pos_session_id,
            table_id: payload.table_id,
            list_product: [],
          }),
        })
        .then((data) => {
          if (data.PosOrder && data.PosOrder.length > 0) {
            const posOrder = required(data).PosOrder[0];
            if (posOrder) {
              dispatch(setPosOrder(posOrder));
            } else {
              console.warn("không tạo được order");
            }
            return posOrder!;
          }
        })
        .catch(rejectWithValue);
    }
  ),
  [DO_UPDATE_ORDER]: createAsyncThunk(
    thunkName(DO_UPDATE_ORDER),
    (payload: ICreateOrderPayload, { dispatch, rejectWithValue }) => {
      return gqlClient
        .request(mutationCreateOrder, {
          action: "create_from_e_menu",
          action_args: makePayload<typeof payload>({
            pos_session_id: payload.pos_session_id,
            table_id: payload.table_id,
            list_product: payload.list_product,
            other: payload.other,
          }),
        })
        .then((data) => {
          const posOrder = required(data).PosOrder[0];
          if (posOrder) {
            dispatch(setPosOrder(posOrder));

            return posOrder;
          } else {
            console.warn("không update được order");
          }
        })
        .catch(rejectWithValue);
    }
  ),
  [DO_UPDATE_ORDER_LINE]: createAsyncThunk(
    thunkName(DO_UPDATE_ORDER_LINE),
    (payload: IUpdateOrderLinePayload, { dispatch, rejectWithValue }) => {
      return gqlClient
        .request(mutationCreateOrder, {
          action: "update_order_line",
          action_args: makePayload<typeof payload>({
            table_id: payload.table_id,
            lines: payload.lines,
          }),
        })
        .then((data) => {
          if (data.PosOrder && data.PosOrder.length > 0) {
            const posOrder = required(data).PosOrder[0];
            if (posOrder) {
              dispatch(setPosOrder(posOrder));

              return posOrder;
            } else {
              console.warn("không update được order line");
            }
          }
        })
        .catch(rejectWithValue);
    }
  ),

  [DO_GET_ORDER]: createAsyncThunk(
    thunkName(DO_GET_ORDER),
    (
      payload: {
        orderId: number;
      },
      { dispatch, rejectWithValue, getState }
    ) => {
      const { posOrder: oldPosOrder } = getState().orders;
      return gqlClient
        .request(queryPosOrderById, {
          domain: [["id", "=", payload.orderId]],
        })
        .then((data) => {
          if (data.PosOrder && data.PosOrder.length > 0) {
            const posOrder = required(data).PosOrder[0];
            if (posOrder) {
              if (!equals(posOrder, oldPosOrder)) {
                dispatch(setPosOrder(posOrder));
              }
              dispatch(setPosOrder(posOrder));
              return posOrder;
            }
          }
        })
        .catch(rejectWithValue);
    }
  ),
  [DO_GET_ORDER_BY_TABLEID]: createAsyncThunk(
    thunkName(DO_GET_ORDER_BY_TABLEID),
    (
      payload: {
        session_id: number;
        table_id: number;
      },
      { dispatch, rejectWithValue }
    ) => {
      return gqlClient
        .request(queryPosOrderById, {
          domain: [
            ["session_id", "=", payload.session_id],
            ["table_id", "=", payload.table_id],
            ["state", "=", "draft"],
          ],
        })
        .then((data) => {
          if (data.PosOrder && data.PosOrder.length > 0) {
            const posOrder = required(data).PosOrder[0];

            if (posOrder) {
              dispatch(setPosOrder(posOrder));
              return posOrder;
            } else {
              return null;
            }
          }
        })
        .catch(rejectWithValue);
    }
  ),
  [DO_PAYMENT_ORDER_BY_TABLE_ID]: createAsyncThunk(
    thunkName(DO_PAYMENT_ORDER_BY_TABLE_ID),
    (
      payload: {
        order_id: number;
        amount_paid: number;
        payment_method_id: number;
        payment_name: string;
      },
      { dispatch, rejectWithValue }
    ) => {
      return gqlClient
        .request(mutationGetPaymentOrderByTableId, {
          action: "paid_order_ui",
          action_args: makePayload<typeof payload>({
            order_id: payload.order_id, // 52
            amount_paid: payload.amount_paid, // 230000
            payment_method_id: payload.payment_method_id, // 2
            payment_name: payload.payment_name, // "abc"
          }),
        })
        .then((data) => {
          if (data.PosOrder && data.PosOrder.length > 0) {
            const posOrderBase64 = required(data);
            if (posOrderBase64) {
              dispatch(setPaymentOrder(posOrderBase64.PosOrder.map((t) => t)));
              return posOrderBase64;
            } else {
              return null;
            }
          }
        })
        .catch(rejectWithValue);
    }
  ),
};

export interface posOrderIdsPayload {
  id: number;
  name: string;
}

export interface posOrderPaymentPayload {
  id: number;
  name: string;
  base64_pdf: string;
}

export const slice = createSlice({
  name: name,
  initialState: {
    posOrder: { lines: [], id: 0, name: "", amount_total: 0 } as PosOrder,
    cartLines: [] as IListProductPayload[],
    posOrderIds: [] as posOrderIdsPayload[],
    posOrderPayment: [] as posOrderPaymentPayload[],
    passcode: "",
    isLoadingOrders: false,
  },
  reducers: {
    setPosOrder: (state, { payload }: PayloadAction<PosOrder>) => {
      state.posOrder = payload;
    },
    setPaymentOrder: (
      state,
      { payload }: PayloadAction<posOrderPaymentPayload[]>
    ) => {
      state.posOrderPayment = payload;
    },
    setPosOrderIds: (
      state,
      { payload }: PayloadAction<posOrderIdsPayload[]>
    ) => {
      state.posOrderIds = payload;
    },
  },
});

export const { setPosOrder, setPosOrderIds, setPaymentOrder } = slice.actions;
export const mutates = slice.actions;
