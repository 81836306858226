import React, { useEffect, useState } from "react";

import { useLingui } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { Tab } from "@nextui-org/tabs";

import { Head, Tabs } from "@src/components";

import { dynamicActivate } from "@src/libs/i18n";
import * as shops from "@src/model/slices/shops";
import { useDispatch } from "@src/model/store";

const CardLang = () => {
  const dispatch = useDispatch();
  const { i18n } = useLingui();
  const [lang, setLang] = useState("vi");

  let tabs = [
    {
      id: "vi",
      label: "Tiếng Việt",
    },
    {
      id: "en",
      label: "English",
    },
  ];

  useEffect(() => {
    // With this method we dynamically load the catalogs
    dispatch(shops.mutates.setLanguage(lang));

    dynamicActivate(lang);
  }, [lang]);

  return (
    <div className="space-y-3">
      <Head sz="medium15">
        <Trans>{t`choose language`}</Trans>
      </Head>

      <div className="flex items-center justify-center gap-4 py-3">
        <Tabs
          aria-label="Language"
          items={tabs}
          radius="full"
          variant="light"
          onSelectionChange={(val) => {
            const keySelected = val.toString();
            setLang(keySelected);
          }}
        >
          {(item) => <Tab key={item.id} title={item.label}></Tab>}
        </Tabs>
      </div>
    </div>
  );
};

export default CardLang;
