import React from "react";
import { Trans } from "@lingui/macro";

import IconLike from "@src/assets/like.icon.svg";
import { TextFoot, Flex } from "@src/components";

const Highlight = () => {
  return (
    <Flex className="gap-x-1 bg-success-500 border-r border-b border-semantic-success-600 rounded-tl-xl rounded-br-xl pl-1.5 pr-3 py-1 ">
      <div className="text-md">
        <IconLike />
      </div>
      <TextFoot sz="medium11" className="text-white ">
        <Trans>{`RECOMMEND`}</Trans>
      </TextFoot>
    </Flex>
  );
};

export default Highlight;
