import React, { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";

import { useDispatch } from "@src/model/store";
import * as shops from "@src/model/slices/shops";
import { MyToastContainer } from "@src/components/Toast";

export const fetchShopData = () => {
  const dispatch = useDispatch();

  const { shopId } = useParams();

  useEffect(() => {
    if (shopId) {
      dispatch(
        shops.actions.doGetCurrency({
          shopId: parseInt(shopId),
        })
      );
    }
  }, []);
};

const ProductsLayout = () => {
  fetchShopData();

  return (
    <div className="h-screen-mobile w-full mx-auto">
      <MyToastContainer
        position="top-center"
        autoClose={700}
        newestOnTop={true}
        draggable={false}
        pauseOnHover
      />
      <Outlet />
    </div>
  );
};

export default ProductsLayout;
