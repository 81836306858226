import React from "react";
import { Card } from "../Card";
import { CircularProgress } from "../CircularProgress";

export const CardLoading = () => {
  return (
    <Card className="h-screen-mobile flex flex-col w-full bg-transparent justify-center items-center">
      <CircularProgress size="lg" aria-label="Loading..." />
    </Card>
  );
};
