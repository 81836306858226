import React from "react";
import { Route, Routes } from "react-router-dom";

import MainLayout from "@src/layouts/MainLayout";
import ProductsLayout from "@src/layouts/ProductsLayout";

import { Cart } from "@src/pages/Cart";
import { EditPage } from "@src/pages/Edit";
import { Home } from "@src/pages/Home";
import { Employee } from "@src/pages/Employee";
import { Table } from "@src/pages/Table";
import { Menu } from "@src/pages/Menu";
import { MenuDetail } from "@src/pages/MenuDetail";
import { Shop } from "@src/pages/Shop";
import { Success } from "@src/pages/Success";
import { Invitation } from "@src/pages/Invitation";
import { Login } from "@src/pages/Login";
import { Test } from "@src/pages/Test";

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/employee/" element={<Employee />} />
        <Route path="/shops/:shopId" element={<Table />} />
        <Route path="/shops/:shopId/tables/:tableId" element={<Shop />} />
        <Route
          path="/shops/:shopId/tables/:tableId/order/:orderId/invitation"
          element={<Invitation />}
        />
        <Route
          path="/shops/:shopId/tables/:tableId/orders/:orderId/detail/:productId"
          element={<MenuDetail />}
        />

        <Route
          path="/shops/:shopId/tables/:tableId/orders/:orderId/detail/:productId/edit"
          element={<EditPage />}
        />
        <Route element={<ProductsLayout />}>
          <Route
            path="/shops/:shopId/tables/:tableId/orders/:orderId"
            element={<Menu />}
          />
        </Route>
        <Route
          path="/shops/:shopId/tables/:tableId/orders/:orderId/cart"
          element={<Cart />}
        />
        <Route path="/success" element={<Success />} />
        <Route path="/test" element={<Test />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
