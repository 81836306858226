import React from "react";
import { i18n } from "@lingui/core";
import { tix, tw, withProps } from "@src/libs/tix";

import { swapCurrencyPosition } from "@src/libs/money";

import {
  Text,
  Image,
  Head,
  Flex,
  Divider,
  Card,
  CardFooter,
  CardBody,
  Counter,
} from "@src/components";

import Highlight from "./Highlight";

interface CardProps {
  title: string;
  price: number;
  description: string;
  image: string;
  currency: string;
  hasBorder?: boolean;
  onAddToCart: (v: number) => any;
  quantity: number;
  orientation?: "vertical" | "horizontal";
  onClick?: React.MouseEventHandler<any>;
}

export const CardProduct = withProps<CardProps>(tix)(
  {
    variants: {},
  },
  "div",
  (styled) => (_props, ref) => {
    const [El, props] = styled(_props);

    const price = i18n
      .number(props.price, {
        currency: props.currency,
        style: "currency",
      })
      .replace("₫", "đ ");

    const orientation = props.orientation || "horizontal";

    if (orientation === "vertical") {
      return (
        <El className="cursor-pointer">
          <Card className="relative max-w-xxs shadow-none " isBlurred>
            {/* HEADER */}

            <div onClick={props.onClick}>
              <div className="absolute top-0 z-20">
                <Highlight></Highlight>
              </div>
              <CardBody className="overflow-visible p-0">
                <Image
                  width={224}
                  height={undefined as any}
                  alt="food"
                  defaultImage={props.image.replaceAll(
                    "image_1920",
                    "image_128"
                  )}
                  image={props.image}
                  className={tw`overflow-hidden object-cover max-h-24 h-24 aspect-w-1`}
                />
              </CardBody>
            </div>

            {/* BODY */}
            <CardFooter className="text-small flex-col gap-y-3 items-start">
              <Head
                sz="medium15"
                className="line-clamp-3 w-full text-ellipsis h-11"
                onClick={props.onClick}
              >
                {props.title}
              </Head>

              {/* ACTION */}
              <Flex className="w-full justify-between">
                <Text
                  sz="regular13"
                  className="text-neutral-300 line-clamp-1 "
                  onClick={props.onClick}
                >
                  {swapCurrencyPosition(price)}
                </Text>
                <Counter
                  defaultValue={props.quantity}
                  onChange={(selected) => {
                    props.onAddToCart(selected);
                  }}
                ></Counter>
              </Flex>
            </CardFooter>
          </Card>
        </El>
      );
    } else
      return (
        <El className="flex space-x-3 bg-white cursor-pointer">
          {/* LEFT SIDE IMAGE */}
          <Image
            width={80}
            height={80}
            alt=""
            defaultImage={props.image.replaceAll("image_1920", "image_128")}
            image={props.image}
            className={tw`overflow-hidden max-h-20 w-20 object-cover aspect-w-1 rounded-md`}
            onClick={props.onClick}
          />

          {/* CONTENT */}
          <div className="flex-1">
            <Head
              sz="medium15"
              className="text-ellipsis line-clamp-1"
              onClick={props.onClick}
            >
              {props.title}
            </Head>
            <Text
              sz="regular13"
              className="text-neutral-300 line-clamp-1 text-ellipsis h-5"
              onClick={props.onClick}
            >
              {props.description}
            </Text>

            <Flex className="py-3 justify-between ">
              <Text sz="regular13" className="text-neutral-300 line-clamp-1">
                {swapCurrencyPosition(price)}
              </Text>

              {/* BTN ACTION */}
              <Counter
                defaultValue={props.quantity}
                onChange={(selected) => {
                  props.onAddToCart(selected);
                }}
              ></Counter>
            </Flex>
            {props.hasBorder && (
              <div className="pt-1">
                <Divider orientation="horizontal" />
              </div>
            )}
          </div>
        </El>
      );
  }
);
