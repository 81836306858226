import {
  PayloadAction,
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";

import {
  ArrayElement,
  NonNullableNested,
  NullableNestedOriginal,
  dotPrefixer,
  pathOr,
  required,
  typedName,
} from "@src/libs/types";
import { graphql } from "@src/gql";
import { gqlClient } from "@src/model/api";
import {
  CurrencyOfPosConfigQuery,
  PosConfigQuery,
  TableInfoQuery,
  TablesQuery,
  PosOrderWithCodeQuery,
} from "@src/gql/graphql";
import { rootSelector } from "@src/model/store";
import { table } from "console";
import internal from "stream";
import { setPosOrder } from "./orders";

export const name = typedName("shops");
export const thunkName = dotPrefixer(name);

const DO_GET_POS_CONFIG = typedName("doGetPosConfig");
const DO_GET_CURRENCY = typedName("doGetCurrency");
const DO_GET_TABLE = typedName("doGetTable");
const DO_GET_TABLES = typedName("doGetTables");
const DO_GET_POS_ORDER_WITH_CODE = typedName("doGetShopPosOrderWithCode");

export type ShopInfo = NullableNestedOriginal<
  NonNullableNested<PosConfigQuery>["PosConfig"][number]
>;

export type TableInfo = NullableNestedOriginal<
  NonNullableNested<TableInfoQuery>["RestaurantTable"][number]
>;

export type Table = NullableNestedOriginal<
  NonNullableNested<TablesQuery>["PosConfig"][number]["floor_ids"][number]["table_ids"][number]
>;

export type PosOrderWithCode = NullableNestedOriginal<
  NonNullableNested<PosOrderWithCodeQuery>["PosOrder"][number]
>;

type Currency = NullableNestedOriginal<
  NonNullableNested<CurrencyOfPosConfigQuery>["PosConfig"][number]["currency_id"]
>;

const selectTableById = (posId: number) =>
  createSelector(rootSelector, (state) => {
    const table = required(state.shops.tables);
    return table.filter((t) => t.id === posId);
  });

export const selects = {
  selectTableById,
};

const selectPosOrderWithCodeByState = (state: string) =>
  createSelector(rootSelector, (state) => {
    const posOrderWithCode = required(state.shops.posOrderWithCode);
    console.log("selectPosOrderWithCodeByState", posOrderWithCode);
    return posOrderWithCode.filter((t) => t.state);
  });

export const selectPosOrderWithCode = {
  selectPosOrderWithCodeByState,
};

const queryPosConfig = graphql(`
  query PosConfig($domain: QueryInputDomainArg) {
    PosConfig(domain: $domain) {
      address
      id
      display_name
      image_url
      current_session_id {
        id
        name
      }
      floor_ids {
        id
        name
        table_ids {
          id
          name
        }
      }
    }
  }
`);
const queryCurrency = graphql(`
  query CurrencyOfPosConfig($domain: QueryInputDomainArg) {
    PosConfig(domain: $domain) {
      id
      name
      currency_id {
        id
        name
      }
    }
  }
`);

const queryTable = graphql(`
  query TableInfo($domain: QueryInputDomainArg) {
    RestaurantTable(domain: $domain) {
      id
      name
    }
  }
`);

const queryTables = graphql(`
  query Tables($domain: QueryInputDomainArg) {
    PosConfig(domain: $domain) {
      floor_ids {
        table_ids {
          id
          name
        }
        id
        name
      }
    }
  }
`);

const queryPosOrderWithCode = graphql(`
  query PosOrderWithCode($domain: QueryInputDomainArg) {
    PosOrder(domain: $domain) {
      id
      table_id {
        id
        name
      }
      order_no
      state
      config_id {
        id
        has_active_session
      }
    }
  }
`);

export const actions = {
  [DO_GET_POS_CONFIG]: createAsyncThunk(
    thunkName(DO_GET_POS_CONFIG),
    (
      payload: {
        shopId: number;
      },
      { dispatch, rejectWithValue }
    ) => {
      return gqlClient
        .request(queryPosConfig, {
          domain: [["id", "=", [payload.shopId]]],
        })
        .then((data) => {
          const shop = required(data).PosConfig[0];
          if (shop) {
            const table = pathOr<TableInfo>(shop, [
              "floor_ids",
              0,
              "table_ids",
              0,
            ]);
            const currency = pathOr<Currency>(shop, [
              "floor_ids",
              0,
              "currency_id",
            ]);
            dispatch(setShopInfo(shop));
            // dispatch(setTableInfo(table));
            dispatch(setCurrency(currency));
          }
        })
        .catch(rejectWithValue);
    }
  ),
  [DO_GET_TABLE]: createAsyncThunk(
    thunkName(DO_GET_TABLE),
    (
      payload: {
        tableId: number;
      },
      { dispatch, rejectWithValue }
    ) => {
      return gqlClient
        .request(queryTable, {
          domain: [["id", "=", [payload.tableId]]],
        })
        .then((data) => {
          const table = required(data).RestaurantTable[0];
          if (table) {
            dispatch(setTableInfo(table));
          }
        })
        .catch(rejectWithValue);
    }
  ),
  [DO_GET_CURRENCY]: createAsyncThunk(
    thunkName(DO_GET_CURRENCY),
    (
      payload: {
        shopId: number;
      },
      { dispatch, rejectWithValue }
    ) => {
      return gqlClient
        .request(queryCurrency, {
          domain: [["id", "=", [payload.shopId]]],
        })
        .then((data) => {
          const shop = required(data).PosConfig[0];

          if (shop) {
            const currency = shop.currency_id;
            dispatch(setCurrency(currency));
          }
        })
        .catch(rejectWithValue);
    }
  ),
  [DO_GET_TABLES]: createAsyncThunk(
    thunkName(DO_GET_TABLES),
    (
      payload: {
        posId: number;
      },
      { dispatch, rejectWithValue }
    ) => {
      return gqlClient
        .request(queryTables, {
          domain: [["id", "=", [payload.posId]]],
        })
        .then((data) => {
          // Get list tables(id, name) for Table page
          const floors = required(data).PosConfig[0]?.floor_ids;
          if (floors != undefined) {
            const tables = floors.reduce((initial, floor) => {
              return initial.concat(floor.table_ids);
            }, [] as Table[]);
            dispatch(setTables(tables));
          }
        })
        .catch(rejectWithValue);
    }
  ),
  [DO_GET_POS_ORDER_WITH_CODE]: createAsyncThunk(
    thunkName(DO_GET_POS_ORDER_WITH_CODE),
    (
      payload: {
        state: string;
        posId: number;
      },
      { dispatch, rejectWithValue }
    ) => {
      return gqlClient
        .request(queryPosOrderWithCode, {
          domain: [
            ["state", "in", [payload.state]],
            ["config_id", "=", [payload.posId]],
          ],
        })
        .then((data) => {
          // Get parameter(ShopId, TableId, PosConfigId, passCode) for Table page
          const posOrder = required(data).PosOrder;
          if (posOrder != undefined) {
            dispatch(setPosOrderWithCode(posOrder));
          }
        })
        .catch(rejectWithValue);
    }
  ),
};

interface IState {
  shopInfo: ShopInfo;
  tableInfo: TableInfo;
  currency: Currency;
  tables: Table[];
  posOrderWithCode: PosOrderWithCode[];
  lang: string;
}

export const slice = createSlice({
  name: name,
  initialState: {
    tables: [] as Table[],
    posOrderWithCode: [] as PosOrderWithCode[],
  } as IState,
  reducers: {
    setShopInfo: (state, { payload }: PayloadAction<ShopInfo>) => {
      state.shopInfo = payload;
    },
    setTableInfo: (state, { payload }: PayloadAction<TableInfo>) => {
      state.tableInfo = payload;
    },
    setTables: (state, { payload }: PayloadAction<Table[]>) => {
      state.tables = payload;
    },
    setPosOrderWithCode: (
      state,
      { payload }: PayloadAction<PosOrderWithCode[]>
    ) => {
      state.posOrderWithCode = payload;
    },
    setCurrency: (state, { payload }: PayloadAction<Currency>) => {
      state.currency = payload;
    },
    setLanguage: (state, { payload }: PayloadAction<string>) => {
      state.lang = payload;
      localStorage.setItem("lang", payload);
    },
  },
});

export const {
  setShopInfo,
  setTableInfo,
  setCurrency,
  setTables,
  setPosOrderWithCode,
} = slice.actions;
export const mutates = slice.actions;
