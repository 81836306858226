import { PayloadAction, createSlice, createSelector } from "@reduxjs/toolkit";

import {
  typedName,
  dotPrefixer,
  required,
  NonNullableNested,
  NullableNestedOriginal,
} from "@src/libs/types";
import { graphql } from "@src/gql";
import { createAsyncThunk } from "@src/model/thunk";
import { gqlClient } from "@src/model/api";
import { ProductsQuery, PosCategoriesQuery } from "@src/gql/graphql";
import { equals } from "remeda";
import { rootSelector } from "../store";

export type ListProduct = NullableNestedOriginal<
  NonNullableNested<ProductsQuery>["ProductProduct"][0]
>[];
export type ProductDetail = NullableNestedOriginal<
  NonNullableNested<ProductsQuery>["ProductProduct"][0]
>;
export type OptionProduct = NullableNestedOriginal<
  NonNullableNested<ProductsQuery>["ProductProduct"][0]["option_product_ids"][0]
>;

export type ListCategory = NullableNestedOriginal<
  NonNullableNested<PosCategoriesQuery>["PosConfig"][0]["iface_available_categ_ids"][0]
>[];

export const name = typedName("products");
export const thunkName = dotPrefixer(name);

const DO_GET_PRODUCTS = typedName("doGetProducts");
const DO_GET_PRODUCTS_RECOMMEND = typedName("doGetProductsRecommend");
const DO_GET_ALL_PRODUCTS = typedName("doGetAllProducts");
const DO_GET_CATEGORIES = typedName("doGetCategories");
const DO_GET_PRODUCT_BY_ID = typedName("doGetProductById");

const queryPosCategories = graphql(`
  query PosCategories($domain: QueryInputDomainArg) {
    PosConfig(domain: $domain) {
      iface_available_categ_ids {
        id
        name
      }
    }
  }
`);
const queryPosCategoriesProducts = graphql(`
  query PosCategoriesProducts($domain: QueryInputDomainArg) {
    PosCategory(domain: $domain) {
      id
      name
    }
  }
`);
// price_incl_tax
const queryProducts = graphql(`
  query Products($domain: QueryInputDomainArg) {
    ProductProduct(domain: $domain) {
      id
      name
      price_incl_tax
      image_url
      description_sale
      recommend
      option_product_ids {
        id
        name
        sub_option_product_ids {
          id
          name
          price_incl_tax
        }
      }
      sub_product_ids {
        id
        price_incl_tax
        name
      }
      pos_categ_id {
        id
        name
      }
    }
  }
`);

const queryAllProducts = graphql(`
  query AllProducts(
    $domain: QueryInputDomainArg
    $limit: Int
    $offset: Int
    $order: String
  ) {
    ProductProduct(
      domain: $domain
      limit: $limit
      offset: $offset
      order: $order
    ) {
      id
      name
      price_incl_tax
      image_url
      description_sale
      recommend
      option_product_ids {
        id
        name
        sub_option_product_ids {
          id
          name
          price_incl_tax
        }
      }
      sub_product_ids {
        id
        price_incl_tax
        name
      }
      pos_categ_id {
        id
        name
      }
    }
  }
`);

const selectCategoryProductsById = (categoryId: number) =>
  createSelector(rootSelector, (state) => {
    const products = required(state.products.allProducts);
    return products.filter((p) => p.pos_categ_id.id === categoryId);
  });

export const selects = {
  selectCategoryProductsById,
};

export const actions = {
  [DO_GET_PRODUCTS]: createAsyncThunk(
    thunkName(DO_GET_PRODUCTS),
    (
      payload: {
        listCategoryId: number[];
      },
      { dispatch, rejectWithValue, getState }
    ) => {
      const { products: oldProducts } = getState().products;

      return gqlClient
        .request(queryProducts, {
          domain: [["pos_categ_id", "in", payload.listCategoryId]],
        })
        .then((data) => {
          const products = required(data).ProductProduct;

          if (!equals(products, oldProducts)) {
            dispatch(setProducts(products));
            localStorage.setItem("cachedProducts", JSON.stringify(products));
          }
        })
        .catch(rejectWithValue);
    }
  ),
  [DO_GET_PRODUCTS_RECOMMEND]: createAsyncThunk(
    thunkName(DO_GET_PRODUCTS_RECOMMEND),
    (
      payload: {
        listCategoryId: number[];
      },
      { dispatch, rejectWithValue, getState }
    ) => {
      return gqlClient
        .request(queryProducts, {
          domain: [
            ["pos_categ_id", "in", payload.listCategoryId],
            ["recommend", "=", true],
          ],
        })
        .then((data) => {
          const products = required(data).ProductProduct;
          dispatch(setProductsRecommend(products));
          return products;
        })
        .catch(rejectWithValue);
    }
  ),
  [DO_GET_ALL_PRODUCTS]: createAsyncThunk(
    thunkName(DO_GET_ALL_PRODUCTS),
    (
      payload: {
        listCategoryId: number[];
        limit: number;
        offset: number;
      },
      { dispatch, rejectWithValue, getState }
    ) => {
      const { allProducts: oldProducts } = getState().products;

      const searchTerm = getState().products.searchTerm;

      return gqlClient
        .request(queryAllProducts, {
          domain: [
            ["pos_categ_id", "in", payload.listCategoryId],
            ["name", "ilike", searchTerm],
          ],
          limit: payload.limit,
          offset: payload.offset,
          order: "pos_categ_id, id",
        })
        .then((data) => {
          const products = required(data).ProductProduct;

          if (!equals(products, oldProducts)) {
            dispatch(setAllProducts(products));
          }
          return products;
        })
        .catch(rejectWithValue);
    }
  ),
  [DO_GET_PRODUCT_BY_ID]: createAsyncThunk(
    thunkName(DO_GET_PRODUCT_BY_ID),
    (
      payload: {
        productId: number;
      },
      { dispatch, rejectWithValue }
    ) => {
      return gqlClient
        .request(queryProducts, {
          domain: [["id", "=", payload.productId]],
        })
        .then((data) => {
          const product = required(data).ProductProduct[0];
          if (product) {
            dispatch(setProductDetail(product));
          }
        })
        .catch(rejectWithValue);
    }
  ),
  [DO_GET_CATEGORIES]: createAsyncThunk(
    thunkName(DO_GET_CATEGORIES),
    (
      payload: {
        shopId: number;
      },
      { dispatch, rejectWithValue, getState }
    ) => {
      const { categories: oldCategories } = getState().products;

      return gqlClient
        .request(queryPosCategories, {
          domain: [["id", "=", payload.shopId]],
        })
        .then(async (data) => {
          const allCats =
            required(data).PosConfig[0]?.iface_available_categ_ids!;
          const catIds: number[] = allCats.map((cat) => cat.id);
          await gqlClient
            .request(queryPosCategoriesProducts, {
              domain: [
                ["has_product", "=", true],
                ["id", "in", catIds],
              ],
            })
            .then((res) => {
              const cats = required(res).PosCategory;

              if (!equals(cats, oldCategories)) {
                dispatch(setCategories(cats));
                localStorage.setItem("cachedCategories", JSON.stringify(cats));
              }
              return cats;
            });
        })
        .catch(rejectWithValue);
    }
  ),
};

interface IState {
  searchTerm: string;
  categories: ListCategory;
  products: ListProduct;
  productDetail?: ProductDetail;
  allProducts: ListProduct;
  productsRecommend: ListProduct;
}

export const slice = createSlice({
  name: name,
  initialState: {
    searchTerm: "",
    categories: [],
    products: [],
    allProducts: [],
    productsRecommend: [],
  } as IState,
  reducers: {
    setSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.allProducts = [];
      state.searchTerm = payload;
    },
    setCategories: (state, { payload }: PayloadAction<ListCategory>) => {
      state.categories = payload;
    },
    setProducts: (state, { payload }: PayloadAction<ListProduct>) => {
      state.products = payload;
    },
    setProductsRecommend: (state, { payload }: PayloadAction<ListProduct>) => {
      state.productsRecommend = payload;
    },
    setProductDetail: (state, { payload }: PayloadAction<ProductDetail>) => {
      state.productDetail = payload;
    },
    updateProducts: (state, { payload }: PayloadAction<ListProduct>) => {
      state.products = payload;
    },
    setAllProducts: (state, { payload }: PayloadAction<ListProduct>) => {
      const isDuplicate = state.allProducts.findIndex((product) => {
        const sameProduct = payload.some(
          (payloadItem) => payloadItem.id === product.id
        );

        return sameProduct;
      });

      // const uniqueValues = new Set([...state.allProducts, ...payload]);
      // state.allProducts = Array.from(uniqueValues);

      if (isDuplicate > -1) {
      } else {
        state.allProducts = [...state.allProducts, ...payload];
      }
    },
  },
});

export const {
  setCategories,
  setProducts,
  updateProducts,
  setProductDetail,
  setAllProducts,
  setProductsRecommend,
} = slice.actions;
export const mutates = slice.actions;
