import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { store } from "@src/model/store";
import { Image } from "@src/components";
import * as user from "@src/model/slices/users";

export const Login = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isErr, showErr] = useState(false);

  // Handle Login
  const handleLogin = () => {
    store
      .dispatch(
        user.actions.doUserLogin({
          username: username,
          password: password,
          isEmployee: true,
        })
      )
      .unwrap()
      .then((res) => {
        if (res) {
          showErr(false);
          navigate(`/employee`);
        } else {
          showErr(true);
        }
      });
  };

  return (
    <div className="h-screen-mobile flex flex-col bg-neutral-900 scroll-smooth text-center">
      <div className="space-y-1">
        <div className="w-80 mx-auto pt-6">
          <Image
            width={310}
            height={undefined as any}
            alt="Shop Avatar"
            image="/shop-avatar.png"
            defaultImage="/shop-avatar.png"
          />
        </div>
      </div>
      <div className="flex flex-col items-center">
        <h1 className="text-2xl font-bold mb-1">Đăng nhập</h1>
        <div className="">( dành cho nhân viên )</div>
        <div className="p-4 flex flex-col w-full max-w-md">
          <div className="flex items-center mb-1">
            <label>Tên đăng nhập:</label>
          </div>
          <input
            type="text"
            id="username"
            className="p-1 border rounded-md"
            value={username}
            onChange={(username) => setUsername(username.target.value)}
          />
          <div className="flex items-center mb-1">
            <label>Mật khẩu:</label>
          </div>
          <input
            type={"password"}
            id="password"
            className="p-1 border rounded-md"
            value={password}
            onChange={(password) => setPassword(password.target.value)}
          />
          {isErr && (
            <p className="text-red-500">
              Tên đăng nhập hoặc mật khẩu sai, mời bạn nhập lại!
            </p>
          )}
        </div>

        <div className="flex flex-col">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-6 rounded focus:outline-none focus:shadow-outline"
            onClick={handleLogin}
          >
            Đăng nhập
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
