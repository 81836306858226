import React, { useEffect } from "react";
import { TableItem } from "@src/components/shared/Table";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "@src/model/store";
import { Flex, Head } from "@src/components";

import * as orders from "@src/model/slices/orders";

export const Employee = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let posOrders = useSelector((state) => state.orders.posOrderIds);

  useEffect(() => {
    dispatch(
      orders.actions.doGetPosOrderIds({
        isPosOrders: false,
      })
    );
  }, []);

  // Check for duplicate data and add isActive to check for State
  // Return PosId
  const uniquePosOrders = Array.from(
    new Set(posOrders.map((posOrder) => JSON.stringify(posOrder)))
  ).map((posOrder) => JSON.parse(posOrder));

  const renderingShops = uniquePosOrders.map((o) => {
    const isActive = !!o;
    return {
      ...o,
      isActive,
    };
  });

  return (
    <div className="h-screen-mobile flex flex-col bg-neutral-900 scroll-smooth">
      <div className="p-4">
        <Head sz="bold18" className="text-neutral-200">
          {`Danh sách các Pos`}
        </Head>
        <Flex className="items-center gap-x-4 justify-between">
          <div className="table-grid">
            {renderingShops.map((o) => {
              return (
                <TableItem
                  key={o.id}
                  name={o.name}
                  isActive={o.isActive}
                  isDisabled={false}
                  onClick={() => {
                    navigate(`/shops/${o.id}`);
                  }}
                ></TableItem>
              );
            })}
          </div>
        </Flex>
      </div>
    </div>
  );
};
