import { Button } from "@src/components";

interface TableItemProps {
  name: string;
  isActive: boolean;
  isDisabled: boolean;
  onClick: () => void;
}

export const TableItem = (props: TableItemProps) => {
  const { name, isActive, isDisabled } = props;

  return (
    <Button
      className={isActive ? "" : ""}
      color="success"
      isDisabled={!isActive}
      onClick={props.onClick}
    >
      <b>{name}</b>
    </Button>
  );
};
