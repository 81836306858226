import React from "react";
import { Trans } from "@lingui/macro";
import { useNavigate } from "react-router-dom";

import { Head, Image, Button, Text } from "@src/components";

export const Success = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    // 👇️ replace set to true
    // navigate(`/shops/${shopId}/tables/${tableId}/orders/${orderId}`, {
    //   replace: true,
    // });
    navigate(-2);
  };

  return (
    <div className="text-center relative">
      <div className="absolute top-1/2 translate-y-1/2 px-10">
        <Image
          width={310}
          height={undefined as any}
          alt="Success"
          image="/success-image.png"
          defaultImage="/success-image.png"
        />
        <Head sz="bold18" className="text-neutral-100 pt-4">
          <Trans>{`SENT_ORDERS_LIST`}</Trans>
        </Head>
        <Text sz="regular15" className="text-neutral-200">
          <Trans>{`ORDER_SENT_NOTIFY_STAFF`}</Trans>
        </Text>
        <div className="pt-6">
          <Button onClick={handleClick}>
            <Trans>{`UNDERSTOOD`}</Trans>
          </Button>
        </div>
      </div>
    </div>
  );
};
