import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDisclosure } from "@nextui-org/modal";
import { Trans } from "@lingui/macro";
/**
 * Utils
 */
import { required } from "@src/libs/types";
import { debounce } from "@src/libs/utils";
import { tw } from "@src/libs/tix";

/**
 * Components
 */
import {
  Flex,
  Floating,
  Searchbar,
  CardProduct,
  Button,
  Skeleton,
} from "@src/components";
import { MenuCategoryProducts } from "@src/components/shared/MenuCategoryProducts";
import { PopupLayout } from "@src/layouts/PopupLayout";

// blocks

/**
 * Store
 */
import { useDispatch, useSelector } from "@src/model/store";
// slices
import * as orders from "@src/model/slices/orders";
import * as productsSlice from "@src/model/slices/products";

/**
 * Types
 */
export interface CartWithOrderId {
  listProduct: orders.IListProductPayload[];
  orderId: string;
}

/**
 * Page Menu
 */

export const Menu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { shopId, orderId, tableId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchParams] = useSearchParams();

  const passcode = searchParams.get("passcode");

  // Get categoryId from URL
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [activeCategoryId, setActiveCategoryId] = useState(0);

  // Creat ref to scroll
  const scrollRefs = useRef<(HTMLElement | null)[]>([]);
  const navRefs = useRef<(HTMLElement | null)[]>([]);

  // Get currency from redux store for display correct price format
  const currency = useSelector((state) => state.shops.currency);
  const searchTerm = useSelector((state) => state.products.searchTerm);

  // Get all products and categories from redux store and apply required type
  const allCategories = useSelector((state) =>
    required(state.products.categories)
  );

  const cartLines = useSelector(orders.selectPosOrderByState("draft")).lines;

  // Handle recommended products
  const recommendedProducts = useSelector((state) =>
    required(state.products.productsRecommend)
  );

  const totalQuantityInCart = useMemo(() => {
    if (!cartLines) {
      return 0;
    }
    return cartLines.reduce((total, product) => total + (product.qty || 0), 0);
  }, [cartLines]);

  const handleAddToCart = useCallback(
    (productId: number, quantity: number) => {
      const lastIndex = cartLines.reduceRight((lastIndex, line, index) => {
        if (line.product_id.id === productId && lastIndex === -1) {
          return index;
        }
        return lastIndex;
      }, -1);

      if (lastIndex > -1) {
        const line: orders.IUpdateOrderLinePayload = {
          table_id: parseInt(tableId!),
          lines: [
            {
              product_id: productId,
              qty: quantity,
              state: "draft",
              line_id: cartLines[lastIndex]!.id,
            },
          ],
        };
        dispatch(orders.actions.doUpdateOrderLine(line));
      } else {
        const line: orders.IUpdateOrderLinePayload = {
          table_id: parseInt(tableId!),
          lines: [
            {
              product_id: productId,
              qty: quantity,
              state: "draft",
              order_id: parseInt(orderId!),
            },
          ],
        };
        dispatch(orders.actions.doUpdateOrderLine(line));
      }
    },
    [cartLines]
  );

  const debounceHandleAddToCart = debounce(handleAddToCart, 400);

  // Go to Cart
  const handleGoToPageCart = () => {
    navigate(`/shops/${shopId}/tables/${tableId}/orders/${orderId}/cart`);
  };

  // find quantity product in list
  const computeProductQuantity = (productIdToFind: number) => {
    if (!cartLines) {
      return 0;
    }
    const quantityProductInCart = cartLines
      .filter((product) => product.product_id.id === productIdToFind)
      .reduce((total, product) => total + (product.qty || 0), 0);

    return quantityProductInCart;
  };

  // Fetch data
  useEffect(() => {
    if (shopId) {
      setLoadingCategories(true);

      dispatch(
        productsSlice.actions.doGetCategories({
          shopId: parseInt(shopId),
        })
      )
        .unwrap()
        .then((res) => {
          setLoadingCategories(false);
        });
    }
  }, []);

  useEffect(() => {
    dispatch(
      productsSlice.actions.doGetProductsRecommend({
        listCategoryId: allCategories.map((cat) => cat.id),
      })
    );
  }, []);

  useEffect(() => {
    if (orderId) {
      dispatch(
        orders.actions.doGetOrder({
          orderId: parseInt(orderId),
        })
      )
        .unwrap()
        .then((res) => {
          if (passcode !== res?.order_no) {
            navigate("/");
          }
        });
    }
  }, []);

  const handleGoToDetail = (productId: number) => {
    navigate(
      `/shops/5/tables/${tableId}/orders/${orderId}/detail/${productId}`
    );
  };

  const debouncedSearch = debounce((term: string) => {
    dispatch(productsSlice.mutates.setSearchTerm(term));
  }, 1000);

  //  Handle get position on screen
  const handlerGetElementOnScreen = useCallback((e: Event) => {
    const scrollRefsElements = scrollRefs.current;

    scrollRefsElements.forEach((el: Element | null, i: number) => {
      if (el) {
        const rect = el.getBoundingClientRect();
        const elemTop = rect.top;
        const elemBottom = rect.bottom;
        const isVisible =
          elemTop < window.innerHeight / 2 &&
          elemBottom > window.innerHeight / 2;
        if (isVisible) {
          navRefs.current[i]?.scrollIntoView({
            behavior: "smooth",
            inline: "center",
          });
          setActiveCategoryId(i);
        }
      }
    });
  }, []);

  // AddEventListener when scroll menu
  useEffect(() => {
    const debouncedScrollHandler = debounce(handlerGetElementOnScreen, 2000);
    const productElement = document.getElementById("product");

    productElement?.addEventListener("scroll", debouncedScrollHandler, true);

    return () => {
      productElement?.removeEventListener(
        "scroll",
        debouncedScrollHandler,
        true
      );
    };
  }, []);

  // PAGE MENU
  return (
    <PopupLayout onClose={onClose} isOpen={isOpen}>
      <div className="h-screen-mobile flex flex-col bg-neutral-900 scroll-smooth">
        {/* HEADER */}

        <div className="p-4">
          <Flex className="items-center gap-x-4 justify-between">
            {/* SEARCH PRODUCTS BAR */}

            <Searchbar
              onValueChange={(term: string) => {
                debouncedSearch(term);
              }}
              defaultValue={searchTerm}
            ></Searchbar>

            <Button
              color="primary"
              variant="light"
              size="md"
              className="flex-shrink-0 px-0"
              onClick={onOpen}
            >
              <Trans>{`ORDER_CODE`}</Trans>
            </Button>
          </Flex>

          {/* LIST CATEGORY */}
          <div
            id="categories"
            className="overflow-x-scroll overflow-hidden z-10 pt-4 no-scrollbar"
          >
            {loadingCategories ? (
              <div className="flex gap-2">
                <Skeleton className="h-9 w-20 rounded-full" />
                <Skeleton className="h-9 w-20 rounded-full" />
                <Skeleton className="h-9 w-20 rounded-full" />
                <Skeleton className="h-9 w-20 rounded-full" />
                <Skeleton className="h-9 w-20 rounded-full" />
              </div>
            ) : (
              <div className="flex space-x-2">
                {allCategories.map((item) => (
                  <div
                    key={item.id}
                    id={item.id.toString()}
                    className={`rounded-full flex text-center items-center h-9 px-4 py-2
                  text-xs2 font-medium w-full
                  ${
                    activeCategoryId === item.id
                      ? "bg-semantic-warning-600 text-semantic-warning-400"
                      : "bg-white border border-neutral-800 text-neutral-200"
                  }
                  `}
                    ref={(element) => (navRefs.current[item.id] = element)}
                  >
                    <a
                      href={`#categoryId-${item.id}`}
                      className={tw`relative line-clamp-1 w-full whitespace-nowrap`}
                      onClick={async (e) => {
                        e.preventDefault();
                        await scrollRefs.current[item.id]?.scrollIntoView({
                          behavior: "smooth",
                        });
                        setActiveCategoryId(item.id);
                      }}
                    >
                      {item.name}
                    </a>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* BODY */}
        <div
          id="product"
          className="flex-1 overflow-y-scroll no-scrollbar p-4 pt-0"
        >
          {/* SPECIAL */}

          <Flex className="snap-x overflow-scroll gap-x-4 [&>*]:flex-shrink-0 [&>*]:w-52 pb-4 snap-mandator [&>*]:snap-always [&>*]:snap-start no-scrollbar">
            {recommendedProducts.length > 0 &&
              recommendedProducts.map((p) => (
                <CardProduct
                  key={p.id}
                  title={p.name}
                  price={p.price_incl_tax}
                  image={p.image_url}
                  description={p.description_sale || ""}
                  currency={currency?.name || "VND"}
                  hasBorder={false}
                  orientation="vertical"
                  quantity={computeProductQuantity(p.id)}
                  onAddToCart={(quantity: number) => {
                    if (p.option_product_ids.length > 0) {
                      handleGoToDetail(p.id);
                    } else {
                      debounceHandleAddToCart(p.id, quantity);
                    }
                  }}
                  onClick={() => handleGoToDetail(p.id)}
                ></CardProduct>
              ))}
          </Flex>

          {/* CATEGORY PRODUCTS */}
          <div className="space-y-4 scroll-smooth">
            {allCategories.map((cat) => {
              return (
                <MenuCategoryProducts
                  key={cat.id}
                  id={`categoryId-${cat.id}`}
                  ref={(element) => (scrollRefs.current[cat.id] = element)}
                  navigateLink={`/shops/${shopId}/tables/${tableId}/orders/${orderId}`}
                  categoryId={cat.id}
                  categoryName={cat.name}
                />
              );
            })}
          </div>

          {/* CART */}
          <Floating
            color="primary"
            className="fixed bottom-4 z-20"
            counter={totalQuantityInCart}
            onClick={() => handleGoToPageCart()}
          ></Floating>
        </div>
      </div>
    </PopupLayout>
  );
};
