import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { t, Trans } from "@lingui/macro";

/**
 * Store
 */
import { useDispatch, useSelector } from "@src/model/store";
import * as products from "@src/model/slices/products";
import * as orders from "@src/model/slices/orders";

/**
 * Components
 */
import {
  Button,
  Card,
  Image,
  CardHeader,
  Divider,
  CardBody,
  Head,
  RadioGroup,
  Radio,
  CheckboxGroup,
  Checkbox,
  Flex,
  Text,
  Counter,
  CardLoading,
  Textarea,
} from "@src/components";
import { PopupLayout } from "@src/layouts/PopupLayout";

/**
 * Utils
 */
import { tw } from "styled-tix";
import { required } from "@src/libs/types";
import { convertPrice } from "@src/libs/money";

// icons
import IconBack from "@src/assets/back.icon.svg";
import { useDisclosure } from "@nextui-org/react";
import { toast } from "@src/components/Toast";

export const MenuDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { productId, tableId, orderId } = useParams();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const productDetail = useSelector((state) =>
    required(state.products.productDetail)
  );

  const currency = useSelector(
    (state) => state.shops.currency || { name: "vnd" }
  );

  const [note, setNote] = useState("");
  const [qty, setQty] = useState(1);
  const [checkboxSelected, setCheckboxSelected] = useState<string[]>([]);
  const [radioSelected, setRadioSelected] = useState<
    orders.ISubOptionPayload[]
  >([]);

  const subProductIds = checkboxSelected.map((strNumber) =>
    parseInt(strNumber)
  );

  const calculateRadioAndCheckboxPrice = () => {
    let checkboxPrice = 0;
    let radioPrice = 0;

    // Tính giá cho checkbox
    for (const selectedId of subProductIds) {
      const checkbox = productDetail?.sub_product_ids.find(
        (checkbox) => checkbox.id === selectedId
      );

      if (checkbox) {
        checkboxPrice += checkbox.price_incl_tax || 0;
      }
    }

    // Tính giá cho radio
    const allradioSelectedIds = radioSelected.flatMap(
      (item) => item.sub_option_product_ids
    );
    const allSubOptionIds = productDetail?.option_product_ids.flatMap(
      (item) => item.sub_option_product_ids
    );

    allradioSelectedIds.forEach((radio) => {
      const matchedSubOption = allSubOptionIds?.find((sub) => sub.id === radio);
      if (matchedSubOption) {
        radioPrice += matchedSubOption.price_incl_tax || 0;
      }
    });

    // Tính tổng giá từ checkboxSelected và radioSelected
    return checkboxPrice + radioPrice;
  };

  const totalPrice = productDetail?.price_incl_tax
    ? (productDetail.price_incl_tax + calculateRadioAndCheckboxPrice()) * qty
    : 0;

  useEffect(() => {
    if (productId) {
      dispatch(
        products.actions.doGetProductById({
          productId: parseInt(productId),
        })
      );
    }
  }, []);

  const handleAddToCart = () => {
    const _productId = parseInt(productId!);

    const optionIds: orders.ISubOptionPayload[] = radioSelected;

    //  create line payload
    const productOrder: orders.IUpdateOrderLinePayload = {
      table_id: parseInt(tableId!),
      lines: [
        {
          product_id: _productId,
          qty: qty,
          state: "draft",
          order_id: parseInt(orderId!),
          option_ids: optionIds,
          sub_product_ids: subProductIds,
          note: note,
        },
      ],
    };

    dispatch(orders.actions.doUpdateOrderLine(productOrder)).then((res) =>
      navigate(-1)
    );

    if (qty > 0) {
      toast({
        type: "success",
        message: `${t`ITEM_ADDED`} ${productDetail?.name}`,
      });
    }
  };

  // Back to page menu
  const handleClick = () => {
    navigate(-1);
  };

  return (
    <PopupLayout isOpen={isOpen} onClose={onClose}>
      {productDetail && productDetail.id === parseInt(productId!) ? (
        <div className="h-screen-mobile overflow-y-auto flex flex-col w-full relative">
          <Flex className="items-center justify-between absolute top-4 left-0 right-0 w-full z-20 px-4">
            <IconBack
              className="text-xxl cursor-pointer"
              onClick={handleClick}
            ></IconBack>

            <Button
              color="primary"
              variant="light"
              size="md"
              className="flex-shrink-0 px-0"
              onClick={onOpen}
            >
              <Trans>{`ORDER_CODE`}</Trans>
            </Button>
          </Flex>
          <div className="h-80">
            <Image
              width={448}
              height={undefined as any}
              alt="Shop Avatar"
              defaultImage={
                productDetail.image_url?.replace("image_1920", "image_128") ||
                ""
              }
              image={productDetail.image_url || ""}
              className={tw`h-full w-full object-contain pointer-events-none`}
            />
          </div>

          <Card
            className="flex-grow rounded-b-none z-20 border-b"
            shadow="none"
          >
            <CardHeader className="p-4 flex flex-col items-start gap-y-1.5">
              <Flex className="justify-between w-full">
                <Head sz="bold18">{productDetail.name}</Head>
                <Head sz="bold18" className="line-clamp-1 shrink-0 pl-2">
                  {convertPrice(
                    productDetail.price_incl_tax || 0,
                    currency.name || "VND"
                  )}
                </Head>
              </Flex>
              <div>
                <Text sz="regular13" className="text-neutral-300">
                  {productDetail.description_sale}
                </Text>
              </div>
              {/* NOTE */}

              <Textarea
                minRows={1}
                variant="faded"
                placeholder={t`note`}
                onValueChange={(val: string) => setNote(val)}
              />
            </CardHeader>
            {productDetail.option_product_ids &&
              productDetail.option_product_ids?.length > 0 && (
                <Divider className="text-neutral-800" />
              )}

            <CardBody className="py-0 pt-4">
              <div className="flex-1 overflow-y-scroll no-scrollbar z-20">
                {/* RADIO OPTION */}

                {productDetail.option_product_ids &&
                  productDetail.option_product_ids.length > 0 && (
                    <MyRadioGroup
                      listOption={required(productDetail.option_product_ids)}
                      currency={currency.name || "VND"}
                      onRadioSelectedChange={setRadioSelected}
                    ></MyRadioGroup>
                  )}

                {/* CHECKBOX OPTION */}

                {productDetail.sub_product_ids &&
                  productDetail.sub_product_ids.length > 0 && (
                    <CheckboxGroup
                      label={`${t`ADD`} - topping`}
                      onValueChange={(val) => {
                        setCheckboxSelected(val);
                      }}
                    >
                      {productDetail.sub_product_ids.map((option, index) => {
                        const optionData = option;
                        return (
                          <Checkbox
                            key={index}
                            value={optionData.id.toString()}
                            description={convertPrice(
                              optionData.price_incl_tax
                                ? optionData.price_incl_tax
                                : 0,
                              currency.name || "VND"
                            )}
                          >
                            {optionData.name}
                          </Checkbox>
                        );
                      })}
                    </CheckboxGroup>
                  )}
              </div>
            </CardBody>
          </Card>
          <div className="flex p-4 gap-x-4 ">
            <Counter
              defaultValue={qty}
              onChange={(v: number) => {
                setQty(v);
              }}
            ></Counter>

            <Button className="w-full" size="lg" onClick={handleAddToCart}>
              {qty === 0
                ? t`RETURN_TO_MENU`
                : `${t`ADD_TO_CART`} - ${convertPrice(
                    totalPrice,
                    currency.name || "VND"
                  )}`}
            </Button>
          </div>
        </div>
      ) : (
        <CardLoading></CardLoading>
      )}
    </PopupLayout>
  );
};

interface RadioProps {
  listOption: products.OptionProduct[];
  currency: string;
  onRadioSelectedChange: (newRadioSelected: orders.ISubOptionPayload[]) => void;
}

const MyRadioGroup: React.FC<RadioProps> = (props) => {
  const listOption = props.listOption;

  const getDefaultSelectedOptions = () => {
    const defaultOptions: orders.ISubOptionPayload[] = [];

    listOption.forEach((optionGroup) => {
      if (
        optionGroup.sub_option_product_ids &&
        optionGroup.sub_option_product_ids[0]
      ) {
        defaultOptions.push({
          option_product_ids: [optionGroup.id],
          sub_option_product_ids: [optionGroup.sub_option_product_ids[0]!.id],
        });
      }
    });

    return defaultOptions;
  };

  const [radioSelected, setRadioSelected] = useState<
    orders.ISubOptionPayload[]
  >(getDefaultSelectedOptions());

  const handleGetOptions = (optionId: number, optionGroupId: number) => {
    const updatedOptions = [...radioSelected];

    const selectedOptionIndex = radioSelected.findIndex(
      (selectedOption) => selectedOption.option_product_ids[0] === optionGroupId
    );

    if (selectedOptionIndex !== -1) {
      // Option đã tồn tại trong selectedOptions, cập nhật lại giá trị
      updatedOptions[selectedOptionIndex] = {
        option_product_ids: [optionGroupId],
        sub_option_product_ids: [optionId],
      };
    } else {
      // Option chưa tồn tại trong selectedOptions, thêm nó vào mảng
      updatedOptions.push({
        option_product_ids: [optionGroupId],
        sub_option_product_ids: [optionId],
      });
    }

    setRadioSelected(updatedOptions);
  };

  useEffect(() => {
    // Gọi hàm callback khi giá trị radioSelected thay đổi
    props.onRadioSelectedChange(radioSelected);
  }, [radioSelected]);

  return (
    <div>
      {listOption.map((optionGroup) => {
        if (optionGroup && optionGroup.sub_option_product_ids) {
          const radioKey = `${optionGroup.id}`;

          return (
            <RadioGroup
              key={radioKey}
              label={optionGroup.name}
              value={radioSelected
                .find((s) => s.option_product_ids[0] === optionGroup.id)
                ?.sub_option_product_ids[0]?.toString()}
              onValueChange={(val) => {
                handleGetOptions(parseInt(val), optionGroup.id);
              }}
            >
              {optionGroup.sub_option_product_ids.map((option, index) => {
                if (option)
                  return (
                    <Radio
                      key={index}
                      value={option.id.toString()}
                      description={convertPrice(
                        option.price_incl_tax || 0,
                        props.currency
                      )}
                    >
                      {option.name}
                    </Radio>
                  );
              })}
            </RadioGroup>
          );
        }
      })}
    </div>
  );
};
