import { Input } from "@src/components";
import React, { useEffect, useRef, useState } from "react";

export const Test = () => {
  const [isOpen, setisOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState("0px");

  const [items, setItems] = useState([
    {
      name: "A",
    },
  ]);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleAddItem = () => {
    setisOpen(!isOpen);
    setItems([
      ...items,
      {
        name: "B",
      },
      {
        name: "B",
      },
      {
        name: "B",
      },
      {
        name: "B",
      },
      {
        name: "B",
      },
      {
        name: "B",
      },
    ]);
    if (containerRef.current) {
      // containerRef.current.style.maxHeight = `${containerRef.current.scrollHeight}px`;
      // setMaxHeight(`${containerRef.current.scrollHeight + 1000}px`);
    }
    // setTimeout(() => {
    //   setisOpen(false);
    // }, 1000);
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.style.maxHeight = `${containerRef.current.scrollHeight}px`;

      setMaxHeight(`${containerRef.current.scrollHeight}px`);
    }
  }, [isOpen]);

  return (
    <div className="">
      <div
        id="cate"
        ref={containerRef}
        className="p-3 bg-red-500 "
        style={{
          // maxHeight: maxHeight,
          // height: maxHeight,
          transition: "max-height 5s ease-in-out",
          overflow: "hidden",
        }}
      >
        <div id="item">
          {items.map((i, idx) => (
            <div key={idx} id="cate-item" className="mt-2 bg-blue-500 ">
              {i.name}
            </div>
          ))}
        </div>
        bottom {maxHeight}
      </div>

      <div id="click-load" onClick={handleAddItem}>
        load
      </div>
    </div>
  );
};
