import axios from "axios";
import { GraphQLClient } from "graphql-request";

// const API_URL = process.env.ODOO_1MENU_URL;
const API_URL = "https://1menu.hcm.init.vn/"; // TODO FIX: dotenv not working in CI/CD

export const client = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

export const gqlClient = new GraphQLClient(API_URL + "graphql", {
  credentials: "include",
});
